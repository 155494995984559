import React, { useRef, useState } from 'react';
import { Button, Grid, Avatar, Badge, IconButton, InputAdornment } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import newUser from './newUserProps';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { updateUser, createUser, uploadPhoto } from 'endpoints/users';
import { toast } from 'react-toastify';
import { isDistributor } from 'utils/distributor';
import { changeOwnership } from 'endpoints/distributors';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Autocomplete from '@mui/material/Autocomplete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export default function CreateUserModal({
  modalOpen,
  closeModal,
  distributors,
  selectedUser,
}) {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [imgPlaceholder, setImgPlaceholder] = useState(null);
  const [passwordShown, setPasswordShown] = useState('password');

  const handlePasswordShown = () => {
    if (passwordShown === 'password') {
      setPasswordShown('text');
    } else {
      setPasswordShown('password');
    }
  }

  const { t } = useTranslation('form');
  const formRef = useRef();
  const queryClient = useQueryClient();

  const { isLoading, refetch } = useQuery(
    ['createUser'],
    () =>
      selectedUser
        ? updateUser(formRef.current.values)
        : createUser(formRef.current.values),
    {
      enabled: false,
      retry: false,
      onSuccess: async data => {
        if (selectedPhoto) {
          const res = await uploadPhoto(data.id, selectedPhoto);
          data.picture = res.imageUrl;
          setSelectedPhoto(null);
          await updateUser(data);
        }
        const message = selectedUser ? t('updatedUser') : t('createdUser');
        toast.success(message);
        queryClient.refetchQueries(['users']);
        if (!isDistributor()) {
          queryClient.refetchQueries(['distributors']);
        }
        closeModal();
      },
      onError: error => {
        toast.error(error.response.data.message);
      },
    }
  );

  const { mutate } = useMutation(selectedUser ? updateUser : createUser);

  const { refetch: changeDistributor } = useQuery(
    'changeOwnership',
    () =>
      changeOwnership(
        formRef.current.values.id,
        formRef.current.values.distributors[0].id
      ),
    { enabled: false, retry: false }
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('isRequired')),
    email: Yup.string()
      .email(t('mustBeEmail'))
      .required(t('isRequired')),
    address: Yup.string().required(t('isRequired')),
    phone: Yup.string().required(t('isRequired')),
    city: Yup.string().required(t('isRequired')),
    country: Yup.string().required(t('isRequired')),
    language: Yup.string().required(t('isRequired')),
    password: !selectedUser
      ? Yup.string()
          .min(8, t('mustBeMinLength', { val: 8 }))
          .max(50, t('mustBeMinLength', { val: 50 }))
          .required(t('isRequired'))
      : null,
    distributors: !isDistributor()
      ? Yup.array()
          .min(1, t('isRequired'))
          .required(t('isRequired'))
      : null,
  });

  const handleSubmit = async user => {
    const payload = { ...user };
    if (isDistributor() && selectedUser) {
      const distributorId = JSON.parse(localStorage.getItem('userData'))?.id;
      payload.distributors = [distributorId];
    }
    if (isDistributor() && !selectedUser) {
      const distributorId = JSON.parse(localStorage.getItem('userData'))?.id;
      payload.distributorId = distributorId;
    }
    if (!isDistributor()) {
      payload.distributorId = payload.distributors[0]?.id;
    }
    if (
      !isDistributor() &&
      selectedUser &&
      user.distributors[0] !== initialValues.distributors[0]
    ) {
      changeDistributor();
    }
    delete payload.distributors;
    mutate(payload, {
      onSuccess: async data => {
        if (selectedPhoto) {
          const res = await uploadPhoto(data.id, selectedPhoto);
          data.picture = res.imageUrl;
          setSelectedPhoto(null);
          await updateUser(data);
        }
        const message = selectedUser ? t('updatedUser') : t('createdUser');
        toast.success(message);
        queryClient.refetchQueries(['users']);
        if (!isDistributor()) {
          queryClient.refetchQueries(['distributors']);
        }
        closeModal();
      },
      onError: error => {
        toast.error(error.response.data.message);
      },
    });
  };

  const handleFileChange = e => {
    if (
      !e.target.files[0].type.startsWith('image/') ||
      !/\.(jpe?g|png|gif)$/i.test(e.target.files[0].name)
    ) {
      toast.error(t('invalidPicture'));
      return;
    }
    const formData = new FormData();
    formData.append('photo', e.target.files[0]);
    setSelectedPhoto(formData);
    const reader = new FileReader();
    reader.onload = event => {
      setImgPlaceholder(event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const generatePassword = () => {
    const chars =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 8; i++) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  };

  const findDistributor = () => {
    if (
      selectedUser.distributors[0] ===
      JSON.parse(localStorage.getItem('userData'))?.id
    ) {
      return JSON.parse(localStorage.getItem('userData'));
    } else {
      return distributors.find(el => el.id === selectedUser.distributors[0]);
    }
  };

  const initialValues = selectedUser
    ? { ...selectedUser, distributors: [findDistributor()] }
    : newUser;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {formik => (
        <Dialog
          open={modalOpen}
          onClose={closeModal}
          disableBackdropClick={isLoading}
          disableEscapeKeyDown={isLoading}
        >
          <DialogTitle>
            {selectedUser ? t('userChange') : t('userCreate')}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <label htmlFor="upload-photo">
                  <Badge
                    badgeContent={<AddAPhotoIcon fontSize="small" />}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <Avatar
                      src={
                        imgPlaceholder ||
                        `${formik.values.picture}?timestamp=${new Date()}`
                      }
                      style={{ width: 50, height: 50 }}
                    ></Avatar>
                    <input
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                  </Badge>
                </label>
              </Grid>
              <Grid item xs={12} md={10}>
                <TextField
                  label={t('name')}
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('email')}
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('organization.organizationGeneralSettings.address')}
                  variant="outlined"
                  fullWidth
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('organization.organizationGeneralSettings.phone')}
                  variant="outlined"
                  fullWidth
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('organization.organizationGeneralSettings.city')}
                  variant="outlined"
                  fullWidth
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('organization.organizationGeneralSettings.country')}
                  variant="outlined"
                  fullWidth
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('organization.organizationGeneralSettings.language')}
                  variant="outlined"
                  fullWidth
                  name="language"
                  value={formik.values.language}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.language && Boolean(formik.errors.language)
                  }
                  helperText={formik.touched.language && formik.errors.language}
                />
              </Grid>
              {!selectedUser ? (
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t(
                      'organization.organizationGeneralSettings.password'
                    )}
                    variant="outlined"
                    fullWidth
                    name="password"
                    type={passwordShown}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handlePasswordShown}>
                            {passwordShown === 'password' ? <VisibilityOffIcon /> : < VisibilityIcon/>}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ) : null}
              {!selectedUser ? (
                <Grid item xs={12} md={6}>
                  <Button
                    onClick={() =>
                      formik.setFieldValue('password', generatePassword())
                    }
                    variant="outlined"
                    color="secondary"
                    fullWidth
                  >
                    {t('generatePassword')}
                  </Button>
                </Grid>
              ) : null}
              {!isDistributor() ? (
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={[
                      JSON.parse(localStorage.getItem('userData')),
                      ...distributors,
                    ]}
                    getOptionLabel={option => option.name}
                    value={formik.values.distributors[0]}
                    onChange={(e, val) =>
                      formik.setFieldValue('distributors', [val])
                    }
                    disableClearable
                    noOptionsText={t('noResults')}
                    renderInput={params => (
                      <TextField
                        label={t('distributor')}
                        variant="outlined"
                        fullWidth
                        name="distributors"
                        error={
                          formik.touched.distributors &&
                          Boolean(formik.errors.distributors)
                        }
                        helperText={
                          formik.touched.distributors &&
                          formik.errors.distributors
                        }
                        {...params}
                      />
                    )}
                  />
                </Grid>
              ) : null}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeModal}
              disabled={isLoading}
              variant="outlined"
              color="default"
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={formik.submitForm}
              disabled={isLoading}
              variant="outlined"
              color="primary"
            >
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
