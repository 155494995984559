import axiosInstance from '.';

export const getUserMaps = async userId => {
  return axiosInstance.get(`/fields/user/${userId}`).then(res => res.data);
};

export const uploadMapFiles = async params => {
  return axiosInstance
    .post('/fields/file/upload/', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*',
      },
    })
    .then(res => res.data);
};

export const createMap = async(params) => {
  return axiosInstance.post('/fields/create', params).then(res => res.data);
};

export const deleteMap = async mapId => {
  return axiosInstance.delete(`/fields/${mapId}`).then(res => res.data);
};

export const updateMap = async({mapId, name, size, userId, geoData}) => {
  const data = { name, size, userId, geoData };
  return axiosInstance
    .put(`/fields/${mapId}/update`,  data)
    .then(res => res.data);
};

export const getAllUserObstacles = async id => {
  return axiosInstance.get(`/fields/${id}/obstacles`).then(res => res.data);
};

export const updateObstacleName = async({id, obstacleId, name}) => {
  const data = { name };
  return axiosInstance
    .put(`/fields/${id}/obstacle/${obstacleId}`,  data)
    .then(res => res.data);
};

export const deleteObstacle = async({id, obstacleId}) => {
  return axiosInstance.delete(`/fields/${id}/obstacle/${obstacleId}`).then(res => res.data);
};

export const createObstacle = async(fieldId, obstacle) => {
  const data = { obstacle: obstacle[0] };
  return axiosInstance
    .post(`/fields/create/obstacle/${fieldId}`,  data)
    .then(res => res.data);
};