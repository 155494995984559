import React, { createContext, useContext, useState } from 'react';

const DifferenceContext = createContext();

export function useDifference() {
  return useContext(DifferenceContext);
}

export function DifferenceProvider({ children }) {
  const [difference, setDifference] = useState(0);
  const [newDifference, setNewDifference] = useState(0);

  const updateDifference = (updatedDifference) => {
    setDifference(updatedDifference);
    setNewDifference(updatedDifference);
  };

  return (
    <DifferenceContext.Provider value={{ difference, newDifference, updateDifference }}>
      {children}
    </DifferenceContext.Provider>
  );
}