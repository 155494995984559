import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    position: 'absolute',
    color: '#fff',
  },
  backdropInline: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'static',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
  },
}));

const CircularLoader = props => {
  const { isLoading, fullscreen } = props;

  const classes = useStyles();
  return (
    <Backdrop
      className={fullscreen ? classes.backdrop : classes.backdropInline}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

CircularLoader.propTypes = {
  isLoading: PropTypes.bool,
  fullscreen: PropTypes.bool,
};

CircularLoader.defaultProps = {
  isLoading: false,
  fullscreen: false,
};

export default CircularLoader;
