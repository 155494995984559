import axiosInstance from '.';

export const getNavigator = async navigatorId => {
  return axiosInstance.get(`/navigators/${navigatorId}`).then(res => res.data);
};

export const updateNavigator = async navigator => {
  return axiosInstance.put(`/navigators/${navigator.id}`, navigator).then(res => res.data);
};

export const deleteNavigator = async navigatorId => {
  return axiosInstance.delete(`/navigators/${navigatorId}`).then(res => res.data);
};

export const createNavigator = async navigator => {
  return axiosInstance.post('/navigators', navigator).then(res => res.data);
};

export const getUserNavigators = async userId => {
  return axiosInstance.get(`/navigators/user/${userId}`).then(res => res.data);
};

export const getUnassignedNavigators = async() => {
  return axiosInstance.get('/navigators/unassigned').then(res => res.data);
};

export const getNavigatorAnalytics = async(navigatorId) => {
  return axiosInstance.get(`/navigators/analytics/${navigatorId}`).then(res => res.data);
};