import axiosInstance from '.';

export const getTasks = async(userId) => {
  return axiosInstance.get(`/tasks/user/${userId}`).then(res => res.data);
};

export const createTask = async task => {
  return axiosInstance.post('/tasks', task).then(res => res.data);
};

export const updateTask = async task => {
  return axiosInstance.put(`/tasks/${task.id}`, task).then(res => res.data);
};

export const deleteTask = async taskId => {
  return axiosInstance.delete(`/tasks/${taskId}`).then(res => res.data);
};