import dayjs from 'dayjs';
import crc from 'crc';

function generateRandomString() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (let i = 0; i < 17; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  const crcResult = crc.crc8(result).toString(10);
  return result + String(crcResult).padStart(3, '0');
}

const newUser = {
  serialNumber: '',
  name: '',
  fromDate: dayjs(),
  toDate: dayjs().year(dayjs().year() + 1),
  userId: '',
  secret: generateRandomString(),
  fromDateViewerLicence: dayjs(),
  toDateViewerLicence: dayjs().year(dayjs().year() + 1),
};

export default newUser;
