import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  colors,
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'react-i18next';
import iconSet from '../../../../assets/icomoon/selection.json';
import Icomoon from 'icomoon-react';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '1px 1px 1px #dadada',
    borderBottom: '1px solid #cccccc',
    backgroundColor: '#ffffff',
  },
  flexGrow: {
    flexGrow: 1,
  },
  seasons: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 5,
    flexBasis: 200,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  seasonInput: {
    margin: 0,
    '& select': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    '& fieldset': {
      borderColor: '#cccccc',
    },
  },
  seasonInputSelectSeason: {
    '& fieldset': {
      borderColor: 'red',
    },
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    border: '1px solid #cccccc',
    borderRadius: 5,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  organizationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation('form');
  const history = useHistory();

  const queryClient = useQueryClient();

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('userData');
    Cookies.remove('remembered_username');
    Cookies.remove('remembered_password');
    Cookies.remove('remember_me');
    queryClient.removeQueries('getUserData');
    history.replace('/auth/login');
    queryClient.invalidateQueries('getAssistanceRequests');
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <Icomoon
          iconSet={iconSet}
          icon="Logo"
          size={150}
          style={{ height: '50px' }}
        />
        <div className={classes.flexGrow} />
        <Hidden>
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon className={classes.logoutIcon} />
            {t('login.signOut')}
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
