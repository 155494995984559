import React, { useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { updateNavigator } from 'endpoints/navigators';
import { toast } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import Autocomplete from '@mui/material/Autocomplete';
import { getUsers } from 'endpoints/users';
import CircularLoader from 'components/Loader/CircularLoader/CircularLoader';

export default function NavigatorModal({
  modalOpen,
  closeModal,
  selectedNavigator,
}) {
  const { t } = useTranslation('form');
  const formRef = useRef();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateNavigator);

  const { data: users, isLoading: usersLoading } = useQuery(
    ['users'],
    getUsers,
    { refetchOnWindowFocus: false },
  );

  if (usersLoading) {
    return <CircularLoader fullscreen />;
  }

  const validationSchema = Yup.object().shape({
    serialNumber: Yup.string().required(t('isRequired')),
    name: Yup.string().required(t('isRequired')),
    fromDate: Yup.date().required(t('isRequired')),
    toDate: Yup.date()
      .min(Yup.ref('fromDate'), t('dateAfter'))
      .required(t('isRequired'))
      .typeError(t('validDate'))
      .test({
        name: 'afterDate',
        exclusive: false,
        message: t('dateAfter'),
        test: function(value) {
          return this.parent.fromDate.getTime() < value.getTime();
        },
      }),
    userId: selectedNavigator ? Yup.object().required(t('isRequired')) : null,
  });

  const handleSubmit = async navigator => {
    const payload = { ...navigator };
    payload.userId = navigator.userId.id;

    mutate(payload, {
      onSuccess: () => {
        const message = selectedNavigator
          ? t('updatedNavigator')
          : t('createdNavigator');
        toast.success(message);
        closeModal();
        queryClient.refetchQueries(['navigators']);
        queryClient.refetchQueries(['getUnassignedNavigators']);
      },
    });
  };
  const initialValues = selectedNavigator;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {formik => (
        <Dialog
          open={modalOpen}
          onClose={closeModal}
          disableBackdropClick={isLoading}
          disableEscapeKeyDown={isLoading}
          disableEnforceFocus
        >
          <DialogTitle>
            {selectedNavigator ? t('navigatorChange') : t('navigatorCreate')}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label={t('serialNumber')}
                  variant="outlined"
                  fullWidth
                  name="serialNumber"
                  value={formik.values.serialNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.serialNumber &&
                    Boolean(formik.errors.serialNumber)
                  }
                  helperText={
                    formik.touched.serialNumber && formik.errors.serialNumber
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label={t('name')}
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.name &&
                    Boolean(formik.errors.name)
                  }
                  helperText={
                    formik.touched.name && formik.errors.name
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="sr"
                >
                  <DateField
                    label={t('validFrom')}
                    variant="outlined"
                    fullWidth
                    disabled
                    name="fromDate"
                    value={formik.values.fromDate}
                    onChange={val => formik.setFieldValue('fromDate', val)}
                    error={
                      formik.touched.fromDate && Boolean(formik.errors.fromDate)
                    }
                    helperText={
                      formik.touched.fromDate && formik.errors.fromDate
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="sr"
                >
                  <DateField
                    label={t('validTo')}
                    variant="outlined"
                    fullWidth
                    name="toDate"
                    value={formik.values.toDate}
                    onChange={val => formik.setFieldValue('toDate', val)}
                    error={
                      formik.touched.toDate && Boolean(formik.errors.toDate)
                    }
                    helperText={formik.touched.toDate && formik.errors.toDate}
                  />
                </LocalizationProvider>
              </Grid>
              {selectedNavigator ? (
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={users}
                    getOptionLabel={option => option.name}
                    value={formik.values.userId}
                    onChange={(e, val) => formik.setFieldValue('userId', val)}
                    disableClearable
                    noOptionsText={t('noResults')}
                    renderInput={params => (
                      <TextField
                        label={t('user')}
                        variant="outlined"
                        fullWidth
                        name="userId"
                        error={
                          formik.touched.userId && Boolean(formik.errors.userId)
                        }
                        helperText={
                          formik.touched.userId && formik.errors.userId
                        }
                        {...params}
                      />
                    )}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} md={12}>
                <TextField
                  label={t('secret')}
                  variant="outlined"
                  fullWidth
                  name="secret"
                  disabled
                  value={formik.values.secret.replace(/(.{5})(?!$)/g, '$1-')}
                  onChange={formik.handleChange}
                  error={formik.touched.secret && Boolean(formik.errors.secret)}
                  helperText={formik.touched.secret && formik.errors.secret}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeModal}
              disabled={isLoading}
              variant="outlined"
              color="default"
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={formik.submitForm}
              disabled={isLoading}
              variant="outlined"
              color="primary"
            >
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
