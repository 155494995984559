import React, { useState, Suspense, useEffect, useRef, useMemo } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from 'react-query';
import { getAssistanceRequests } from 'endpoints/monitoring';
import { ToastContainer, toast } from 'react-toastify';
import { useDifference, DifferenceProvider, updateDifference} from 'utils/NotificationDiff';
import Monitoring from 'views/Monitoring/Monitoring';
import 'react-toastify/dist/ReactToastify.css';

import theme from './theme';
import routes from './routes';
import { GoogleAnalytics } from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/index.scss';

const history = createBrowserHistory();
const queryClient = new QueryClient();

export function MonitoringContainer() {
  const userRole = JSON.parse(localStorage.getItem('userData')).roles[0];
  const [userIdSetter, setUserIdSetter] = useState();
  const [userAssistances, setUserAssistances] = useState();

  const value = useMemo(() => {
    const userId = userRole === 'USER' ? JSON.parse(localStorage.getItem('userData'))?.id : userIdSetter?.id !== undefined ? userIdSetter.id : false;
    return userId;
  }, [userIdSetter?.id]);


  const { data, error, isLoading, refetch } = useQuery(
    ['getAssistanceRequests'],
    () => getAssistanceRequests(value),
    {
      enabled: !!value,
      onSuccess:(res) => setUserAssistances(res),
    },
  );

  useEffect(() => {
    !!value && refetch();
  }, [value]);

  return (
      <Monitoring
        data={userAssistances}
        isLoading={isLoading}
        error={error}
        refetch={refetch}
        setUserIdSetter={setUserIdSetter}
      />
  );
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const App = () => {
  const [mounted, setMounted] = useState('');
  const { updateDifference } = useDifference();
  const [previousLength, setPreviousLength] = useState(0);

  const isMonitoringTab = window.location.pathname === '/monitoring';

  const fetchData = () => {
    const userId = JSON.parse(localStorage.getItem('userData'))?.id;
    queryClient.invalidateQueries('getAssistanceRequests');
    getAssistanceRequests(userId).then((newData) => {
      const newLength = newData ? newData.length : 0;

      const updatedDifference = newLength - previousLength;

      if (!isMonitoringTab && updatedDifference > 0) {
        toast('New assistance request created!', {
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          autoClose: 900000,
          theme: 'dark',
          });
      }

      updateDifference(updatedDifference);
      setPreviousLength(newLength);
    });
  };


  useInterval(() => {
    const isLoggedIn = localStorage.getItem('userData') !== null;
    if (isLoggedIn) {
      fetchData();
    }
  }, 10000);

  useEffect(() => {
    if (!mounted) {
      queryClient.setQueryData(
        'getUserData',
        JSON.parse(localStorage.getItem('userData'))
      );
      setMounted(true);
    }
  }, [mounted, queryClient]);

  return (
    <DifferenceProvider>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div>Loading...</div>}>
          <ToastContainer />
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router history={history}>
                <GoogleAnalytics />
                {routes.map(route => (
                  <Route
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    render={routeProps => {
                      if (route.path === '/monitoring') {
                        return <MonitoringContainer {...routeProps} />;
                      } else {
                        return renderRoutes(routes);
                      }
                    }}
                  />
                ))}
                <ReactQueryDevtools initialIsOpen={false} />
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Suspense>
      </QueryClientProvider>
    </DifferenceProvider>
  );
};

export default () => (
  <DifferenceProvider>
    <App />
  </DifferenceProvider>
);
