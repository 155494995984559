import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { NavBar, TopBar } from './components';
// import {Redirect} from 'react-router';
import CircularLoader from 'components/Loader/CircularLoader';
import { PageLoaderContext } from 'context/Page';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  // navBar: {
  //   zIndex: 3,
  //   width: 256,
  //   minWidth: 256,
  //   flex: '0 0 auto',
  // },
  navBar: {
    position: 'relative',
    zIndex: 3,
    // width: 256,
    width: 270,
    minWidth: 270,
    // minWidth: 256,
    flex: '0 0 auto',
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    display: 'flex',
  },
  circularProgress: {
    margin: 'auto',
  },
}));

const Dashboard = props => {
  const { route } = props;

  const classes = useStyles();
  const [isLoadingPage, setPageLoading] = useState(false);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => setOpenNavBarMobile(true);
  const handleNavBarMobileClose = () => setOpenNavBarMobile(false);

  return (
    <>
      <PageLoaderContext.Provider value={{ isLoadingPage, setPageLoading }}>
        <div className={classes.root}>
          {isLoadingPage && <CircularLoader isLoading={true} />}
          <>
            <TopBar
              className={classes.topBar}
              onOpenNavBarMobile={handleNavBarMobileOpen}
            />
            <div className={classes.container}>
              <NavBar
                className={classes.navBar}
                onMobileClose={handleNavBarMobileClose}
                openMobile={openNavBarMobile}
              />
              <main className={classes.content}>
                <Suspense fallback={<LinearProgress />}>
                  {renderRoutes(route.routes)}
                </Suspense>
              </main>
            </div>
          </>
        </div>
      </PageLoaderContext.Provider>
    </>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
