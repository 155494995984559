import axiosInstance from '.';

export const getUserMachines = async(userId) => {
  return axiosInstance.get(`/machines/user/${userId}`).then(res => res.data);
};

export const createMachine = async machine => {
  return axiosInstance.post('/machines', machine).then(res => res.data);
};

export const updateMachine = async machine => {
  return axiosInstance.put(`/machines/${machine.id}`, machine).then(res => res.data);
};

export const deleteMachine = async machineId => {
  return axiosInstance.delete(`/machines/${machineId}`).then(res => res.data);
};