import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { deleteSingleJob } from 'endpoints/jobs';

export default function DeleteJobModal({
  modalOpen,
  selectedJob,
  handleClose,
}) {
  const queryClient = useQueryClient();
  const { mutate: deleteJob, isLoading } = useMutation(deleteSingleJob);

  const handleDelete = () => {
    deleteJob(selectedJob.id, {
      onSuccess: () => {
        toast.success(t('deletedJob'));
        queryClient.refetchQueries(['getFinishedJobs']);
        handleClose();
      },
    });
  };

  const { t } = useTranslation('form');
  return (
    <Dialog open={modalOpen} onClose={handleClose} disableBackdropClick={isLoading} disableEscapeKeyDown={isLoading}>
      <DialogTitle>{t('confirmDelete')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {selectedJob && t('confirmJobDelete')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading} variant="outlined" color="default">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleDelete}
          disabled={isLoading}
          variant="outlined"
          color="primary"
          autoFocus
        >
          {t('clear')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
