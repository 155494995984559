import dayjs from 'dayjs';

const newOrderProps = {
  name: '',
  agrotechnicalMethod: '',
  date: dayjs(),
  fieldId: '',
  machineId: '',
  navigatorId: '',
};

export default newOrderProps;
