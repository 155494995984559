import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography,
  LinearProgress,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import CoordsInput from './CoordsInput';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useMutation } from 'react-query';
import { uploadMapFiles, createMap } from 'endpoints/fieldMaps';
import getAreaWithoutObstacles from 'utils/AreaCalc';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    minWidth: 800,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  fileInput: {
    display: 'none',
  },
  dropArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px dashed ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    height: 200,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  dropAreaActive: {
    backgroundColor: theme.palette.grey[200],
  },
  dropAreaIcon: {
    fontSize: 60,
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(1),
  },
  dropAreaText: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.grey[400],
  },
  dropAreaContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  browseButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  fileNames: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.grey[800],
  },
  dialogActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),
    marginBottom: theme.spacing(5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`upload-tabpanel-${index}`}
      aria-labelledby={`upload-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const FileItem = ({ file, onRemove }) => {
  const handleRemove = () => {
    onRemove(file);
  };

  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography variant="body1" style={{ marginRight: '10px' }}>
        {file.name}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleRemove}
        size="small"
        ml={1}
      >
        Remove
      </Button>
    </Box>
  );
};

FileItem.propTypes = {
  file: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const AddMapFilesModal = ({ open, onClose, refetch }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [shpFiles, setShpFiles] = useState([]);
  const [shpFileNames, setShpFileNames] = useState([]);
  const [geoJSONFiles, setGeoJSONFiles] = useState([]);
  const [geoJSONFileNames, setGeoJSONFileNames] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [shpName, setShpName] = useState('');
  const [geoJsonName, setGeoJsonName] = useState('');
  const [coordTabShapeName, setCoordTabShapeName] = useState('');
  const [canSave, setCanSave] = useState(false);
  const [inputValid, setInputValid] = useState(true);
  const [geoJsonInputValid, setGeoJsonInputValid] = useState(true);
  const [parsedGeoJSON, setParsedGeoJSON] = useState(null);
  const [coordsGeoJson, setCoordsGeoJson] = useState(null);
  const [isCoordsTabValid, setIsCoordsTabValid] = useState(false);
  const [geoJsonHasName, setGeoJsonHasName] = useState(false);

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setGeoJSONFiles([]);
      setGeoJSONFileNames([]);
      setParsedGeoJSON(null);
    } else if (newValue === 1) {
      setShpFiles([]);
      setShpFileNames([]);
    }
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!open) {
      setShpFiles([]);
      setShpFileNames([]);
      setGeoJSONFiles([]);
      setGeoJSONFileNames([]);
      setShpName('');
      setGeoJsonHasName(false);
    }
  }, [open]);

  useEffect(() => {
    const nameCheck = parsedGeoJSON?.features.some(ele => ele.properties.name);
    nameCheck ? setGeoJsonHasName(true) : setGeoJsonHasName(false);
  }, [parsedGeoJSON, geoJsonHasName]);

  const handleShpFileChange = event => {
    const files = Array.from(event.target.files);

    files.forEach(file => {
      if (file.name.endsWith('.shp')) {
        setShpFiles(prevFiles => [...prevFiles, file]);
        setShpFileNames(prevFileNames => [...prevFileNames, file.name]);
      } else if (file.name.endsWith('.dbf')) {
        setShpFiles(prevFiles => [...prevFiles, file]);
        setShpFileNames(prevFileNames => [...prevFileNames, file.name]);
      } else {
        console.error('Please upload only .shp and .dbf files.');
      }
    });
    event.target.value = null;
  };

  const handleGeoJSONFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = evt => {
        try {
          const geoJSONData = JSON.parse(evt.target.result);
          setParsedGeoJSON(geoJSONData);
          setGeoJsonInputValid(true);
          setGeoJSONFiles([file]);
          setGeoJSONFileNames([file.name]);
        } catch (error) {
          console.error('Invalid JSON file:', error);
          setGeoJsonInputValid(false);
        }
      };
      reader.onerror = evt => {
        console.error('Error reading file:', evt);
        setGeoJsonInputValid(false);
      };
    }
  };

  const validateInput = value => {
    if (value.trim() !== '') {
      setInputValid(true);
      return true;
    } else {
      setInputValid(false);
      return false;
    }
  };

  const handleInputChange = e => {
    const value = e.target.value;
    setShpName(value);
    validateInput(value);
  };

  const validateGeoJsonInput = value => {
    const isValid = value.trim() !== '';
    setGeoJsonInputValid(isValid);
  };

  const handleGeoJsonInputChange = e => {
    const value = e.target.value;
    setGeoJsonName(value);
    validateGeoJsonInput(value);
  };

  const handleDrop = event => {
    event.preventDefault();
    setDragging(false);

    const droppedFiles = Array.from(event.dataTransfer.files);

    if (tabValue === 0) {
      const updatedFiles = droppedFiles.filter(
        file => file.name.endsWith('.shp') || file.name.endsWith('.dbf'),
      );

      if (updatedFiles.length > 0) {
        setShpFiles(prevFiles => [...prevFiles, ...updatedFiles]);
        setShpFileNames(prevFileNames => [
          ...prevFileNames,
          ...updatedFiles.map(file => file.name),
        ]);
      }
    } else if (tabValue === 1) {
      const geoJsonFiles = droppedFiles.filter(
        file => file.name.endsWith('.geojson') || file.name.endsWith('.json'),
      );

      if (geoJsonFiles.length > 0) {
        const file = geoJsonFiles[0];
        const reader = new FileReader();
        reader.onload = e => {
          try {
            const json = JSON.parse(e.target.result);
            setParsedGeoJSON(json);
            setGeoJsonInputValid(true);
          } catch (err) {
            toast.error('Error parsing JSON:', err);
            setGeoJsonInputValid(false);
          }
        };
        reader.onerror = err => {
          toast.error('Error reading file:', err);
          setGeoJsonInputValid(false);
        };
        reader.readAsText(file);
        setGeoJSONFiles(geoJsonFiles);
        setGeoJSONFileNames(geoJsonFiles.map(file => file.name));
      }
    }
  };

  const handleDrag = event => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragEnd = event => {
    event.preventDefault();
    setDragging(false);
  };
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData.id;

  const mutation = useMutation(data => uploadMapFiles(data), {
    onSuccess: async data => {
      const name = shpName;
      const geoData = data;
      const area = getAreaWithoutObstacles(geoData);
      const size = parseFloat(area);
      let params = { userId, name, geoData, size };

      try {
        await createMap(params);
        toast.success('Plot added successfully!');
        refetch();
        onClose();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  useEffect(() => {
    if (tabValue === 0 && shpFiles.length && inputValid && shpName.trim()) {
      setCanSave(true);
    } else if (
      tabValue === 1 &&
      geoJSONFiles.length &&
      geoJsonInputValid &&
      (geoJsonName.trim() || geoJsonHasName)
    ) {
      setCanSave(true);
    } else if (tabValue === 2 && isCoordsTabValid) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [
    shpFiles,
    inputValid,
    shpName,
    tabValue,
    geoJSONFiles,
    geoJsonInputValid,
    geoJsonName,
    isCoordsTabValid,
    geoJsonHasName,
  ]);

  const handleSave = async() => {
    const formData = new FormData();
    shpFiles.forEach(file => {
      formData.append('shp_files', file);
    });
    geoJSONFiles.forEach(file => {
      formData.append('geojson_files', file);
    });

    try {
      if (tabValue === 0) {
        await mutation.mutateAsync(formData);

        try {
          refetch();
          onClose();
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else if (tabValue === 1) {
        try {
          const name = geoJsonName;
          const properties = {
            class: 'parcel',
            name: name,
          };
          const geoData = { ...parsedGeoJSON, properties };
          const area = getAreaWithoutObstacles(geoData);
          const size = parseFloat(area);
          if (geoJsonHasName) {
            let params = { name: 'a', size, userId, geoData };
            await createMap(params);
          } else {
            let params = { name, size, userId, geoData };
            await createMap(params);
          }
          toast.success('Plot added successfully!');
          refetch();
          onClose();
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else if (tabValue === 2) {
        try {
          const name = coordTabShapeName;
          const geoData = coordsGeoJson;
          const area = getAreaWithoutObstacles(coordsGeoJson);
          const size = parseFloat(area);
          let params = { name, size, userId, geoData };
          await createMap(params);
          toast.success('Coordinates added successfully!');
          refetch();
          onClose();
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    } catch (error) {
      toast.error('error.response.data.message');
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleCoordsChange = coords => {
    setCoordsGeoJson(coords);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="upload-modal-title"
        maxWidth="md"
      >
        <DialogTitle id="upload-modal-title" style={{ textAlign: 'center' }}>
          <Typography variant="h2">Upload Files</Typography>
        </DialogTitle>
        {mutation.isLoading && <LinearProgress />}
        <DialogContent>
          <Box width="100%">
            <div className={classes.root}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Upload .shp File" id="upload-tab-0" />
                <Tab label="Upload GeoJSON" id="upload-tab-1" />
                <Tab label="Enter coords" id="upload-tab-2" />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Box width="100%">
                  <TextField
                    id="shp-file-name"
                    label="Name"
                    variant="outlined"
                    className={classes.textField}
                    fullWidth
                    onChange={handleInputChange}
                    error={!inputValid}
                    helperText={!inputValid ? 'Name is required' : ''}
                  />
                </Box>
                <div
                  className={`${classes.dropArea} ${dragging &&
                    classes.dropAreaActive}`}
                  onDrop={handleDrop}
                  onDragOver={handleDrag}
                  onDragLeave={handleDragEnd}
                >
                  <CloudUploadIcon className={classes.dropAreaIcon} />
                  <span className={classes.dropAreaText}>
                    Drag and drop .shp and .dbf files here
                  </span>
                  <input
                    accept=".shp, .dbf"
                    className={classes.fileInput}
                    id="shp-file"
                    type="file"
                    multiple
                    onChange={handleShpFileChange}
                  />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <label htmlFor="shp-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Browse
                      </Button>
                    </label>
                    {shpFileNames.length > 0 && (
                      <Box ml={2}>
                        <Typography variant="body1">
                          {shpFiles.map((file, index) => (
                            <FileItem
                              key={index}
                              file={file}
                              onRemove={() => {
                                setShpFiles(shpFiles.filter(f => f !== file));
                                setShpFileNames(
                                  shpFileNames.filter(
                                    name => name !== file.name,
                                  ),
                                );
                              }}
                            />
                          ))}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Box width="100%">
                  <TextField
                    id="geojson-file-name"
                    label="Name"
                    variant="outlined"
                    className={classes.textField}
                    disabled={geoJsonHasName}
                    value={geoJsonHasName ? '' : geoJsonName}
                    fullWidth
                    onChange={handleGeoJsonInputChange}
                    error={!geoJsonInputValid}
                    helperText={!geoJsonInputValid ? 'Name is required' : ''}
                  />
                </Box>
                <div
                  className={`${classes.dropArea} ${dragging &&
                    classes.dropAreaActive}`}
                  onDrop={handleDrop}
                  onDragOver={handleDrag}
                  onDragLeave={handleDragEnd}
                >
                  <CloudUploadIcon className={classes.dropAreaIcon} />
                  <span className={classes.dropAreaText}>
                    Drag and drop GeoJSON files here
                  </span>
                  <input
                    accept=".geojson, .json"
                    className={classes.fileInput}
                    id="geojson-file"
                    type="file"
                    onChange={handleGeoJSONFileChange}
                  />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <label htmlFor="geojson-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Browse
                      </Button>
                    </label>
                    {geoJSONFileNames.length > 0 && (
                      <Box ml={2}>
                        <Typography variant="body1">
                          {geoJSONFiles.map((file, index) => (
                            <FileItem
                              key={index}
                              file={file}
                              onRemove={() => {
                                setParsedGeoJSON(null);
                                setGeoJSONFiles(
                                  geoJSONFiles.filter(f => f !== file),
                                );
                                setGeoJSONFileNames(
                                  geoJSONFileNames?.filter(
                                    name => name !== file.name,
                                  ),
                                );
                              }}
                            />
                          ))}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <CoordsInput
                  onCoordsChange={handleCoordsChange}
                  setIsCoordsTabValid={setIsCoordsTabValid}
                  setCoordTabShapeName={setCoordTabShapeName}
                />
              </TabPanel>
            </div>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!canSave || mutation.isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={mutation.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

AddMapFilesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};

export default AddMapFilesModal;
