import axiosInstance from '.';

export const getAppVersion = async => {
  return axiosInstance.get('/app-version').then(res => res.data);
};

export const createAppVersion = async appData => {
  return axiosInstance.post('/app-version', appData).then(res => res.data);
};

export const createFile = async formData => {
  return axiosInstance
    .post('/app-version/file', formData)
    .then(res => res.data);
};
