import axiosInstance from '.';

export const getFinishedJobs = async(userId, filters) => {
  const {navigatorId, machineName, fieldName} = filters;
  const fromDate = filters.date;
  let toDate = filters.date;
  if (toDate) toDate = toDate.hour(23).minute(59).second(59);
  console.log(toDate, fromDate);
  const params = {
    navigatorId: navigatorId?.id,
    machineName: machineName?.name,
    fieldName: fieldName?.name,
    fromDate: fromDate?.toISOString(),
    toDate: toDate?.toISOString()
  };
  return axiosInstance.get(`/jobs/finished-jobs/${userId}`, {params}).then(res => res.data);
};

export const getSingleJob = async(jobId) => {
  return axiosInstance.get(`/jobs/finished-job/${jobId}`).then(res => res.data);
};

export const downloadJob = async(jobId) => {
  return axiosInstance.post(`/jobs/finished-job/pdf/${jobId}`, null, {
    headers: {
      'Content-Type': 'application/pdf',
    },
    'responseType': 'arraybuffer',
  }).then(res => res);
};

export const deleteSingleJob = async(jobId) => {
  return axiosInstance.delete(`/jobs/finished-job/${jobId}`).then(res => res.data);
};