import React, { useState, useRef, useEffect } from 'react';
import Sidebar from 'components/Sidebar';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import CircularLoader from 'components/Loader/CircularLoader';
import { getTasks, deleteTask, updateTask } from 'endpoints/tasks';
import { Redirect } from 'react-router';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Checkbox,
  FormControlLabel,
  colors
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import { getUserMaps } from 'endpoints/fieldMaps';
import { getUserMachines } from 'endpoints/machines';
import { getUserNavigators } from 'endpoints/navigators';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import CreateUserModal from './CreateOrderModal';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  GoogleMap,
  useLoadScript,
  Polygon,
  Marker,
  Polyline,
} from '@react-google-maps/api';

const useStyles = makeStyles(theme => ({
  bottomButton: {
    position: 'sticky',
    bottom: '0',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 0,
    width: '100%',
    height: '48px',
    margin: '30px 0 0 0',
  },
  titleBox: {
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    color: 'white',
    marginBottom: '20px',
  },
  accordion: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
  },
  accordionCreated: {
    backgroundColor: colors.grey[200],
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
  },
  accordionReady: {
    backgroundColor: colors.blue[500],
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
  },
  accordionAssigned: {
    backgroundColor: colors.yellow[200],
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
  },
  accordionFinished: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
  },
  accordionDetails: {
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[100],
  },
  accordionButtonStyle: {
    border: '1px solid gray',
    borderRadius: '5px',
    marginTop: '10px',
  },
  mapContainer: {
    height: 'calc(100vh - 128px)',
    overflowY: 'scroll',
  },
}));

const options = {
  mapTypeControl: false, // Disable map/satellite toggle button
  streetViewControl: false, // Disable street view button
  zoomControl: false,
  mapTypeId: 'satellite',
};
const containerStyle = {
  width: '100%',
  height: '100%',
};

export default function WorkOrders() {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(null);
  const [orderForDeletion, setOrderForDeletion] = useState(null);
  const mapRef = useRef();

  const queryClient = useQueryClient();
  const { mutate: deleteOrder } = useMutation(deleteTask, {
    onSuccess: () => {
      toast.success(t('deletedOrder'));
      queryClient.refetchQueries(['getUserOrders']);
      closeDeletionModal();
    },
  });
  const { mutate: updateOrder } = useMutation(updateTask, {
    onError: err => {
      toast.error(t('errorHasOccurred'));
    },
    onSuccess: () => {
      queryClient.refetchQueries(['getUserOrders']);
    },
  });
  const userId = JSON.parse(localStorage.getItem('userData'))?.id;

  const userCountry = JSON.parse(localStorage.getItem('userData'))?.country;
  const userCity = JSON.parse(localStorage.getItem('userData'))?.city;

  const { t } = useTranslation('form');

  const { data: machines, isLoading: machinesLoading } = useQuery(
    'getUserMachines',
    () => getUserMachines(userId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: navigators, isLoading: navigatorsLoading } = useQuery(
    'getUserNavigators',
    () => getUserNavigators(userId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: fields, isLoading: fieldsLoading } = useQuery(
    'getUserFields',
    () => getUserMaps(userId),
    { refetchOnWindowFocus: false }
  );

  const [selectedOrder, setSelectedOrder] = useState('');
  const [selectedPlot, setSelectedPlot] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const classes = useStyles();

  const openModal = order => {
    if (order) {
      order.date = dayjs(order.date);
    }
    setModalOpen(true);
    setSelectedOrder(order);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOrder('');
  };

  const statusHandler = order => {
    const payload = { ...order, status: 'READY' };
    updateOrder(payload);
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: 'getUserOrders',
    queryFn: () => getTasks(userId),
    refetchOnWindowFocus: false,
    retry: 0,
    refetchInterval: 5000,
  });

  const handleExpand = order => (event, newExpanded) => {
    const field = fields.find(field => field.id === order.fieldId);
    if (field) {
      setSelectedPlot(field.geoData[0]);
    }
    setExpandedAccordion(newExpanded ? order.id : false);
  };

  useEffect(() => {
    if (selectedPlot) {
      try {
        const bounds = new window.google.maps.LatLngBounds();
        selectedPlot.geometry.coordinates[0].forEach(coor => {
          const [lng, lat] = coor;
          bounds.extend({ lng, lat });
        });
        fitMapToBounds(bounds);
      } catch (error) {
        console.log(error);
      }
    }
  }, [selectedPlot]);

  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCbJR44eIxzU5YsRiVh0mY91fmTcxxhCHc', // TODO move the key to .env
  });

  const getCenter = callback => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { address: `${userCity}, ${userCountry}` },
      (results, status) => {
        if (status === 'OK') {
          const { lat, lng } = results[0].geometry.location;
          callback({ lat: lat(), lng: lng() });
        } else {
          toast.error(
            'Geocode was not successful for the following reason: ' + status
          );
        }
      }
    );
  };

  const getPolygonCoords = coordinates => {
    if (Array.isArray(coordinates) && Array.isArray(coordinates[0])) {
      if (
        typeof coordinates[0][0] === 'number' &&
        typeof coordinates[0][1] === 'number'
      ) {
        const polygonCoords = coordinates.map(coord => ({
          lat: coord[1],
          lng: coord[0],
        }));
        return polygonCoords;
      } else if (
        Array.isArray(coordinates[0][0]) &&
        typeof coordinates[0][0][0] === 'number' &&
        typeof coordinates[0][0][1] === 'number'
      ) {
        const polygonCoords = coordinates[0].map(coord => ({
          lat: coord[1],
          lng: coord[0],
        }));
        return polygonCoords;
      }
    }

    console.error('Invalid Polygon coordinates structure');
    console.error('coordinates: ', coordinates);
    return [];
  };

  const getLatLng = coord => {
    return { lat: coord[1], lng: coord[0] };
  };

  const fitMapToBounds = bounds => {
    if (mapRef.current) {
      const googleMapInstance = mapRef.current.state.map;
      googleMapInstance.fitBounds(bounds);
    }
  };

  useEffect(() => {
    if (map) {
      getCenter(setCenter);
    }
  }, [map]);

  const onLoad = mapInstance => {
    setMap(mapInstance);
  };

  const closeDeletionModal = () => {
    setOrderForDeletion(null);
  };

  const getColorClass = (status) => {
    console.log(status);
    switch (status) {
      case 'READY':
        return 'accordionReady';
      case 'ASSIGNED':
        return 'accordionAssigned';
      case 'FINISHED':
        return 'accordionFinished';
      default:
        return 'accordionCreated';
    }
  };

  if (isLoading) {
    return <CircularLoader isLoading={true} />;
  }

  if (isError) {
    return <Redirect to="/errors/error-404" />;
  }

  if (fieldsLoading || navigatorsLoading || machinesLoading) {
    return <CircularLoader />;
  }

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';

  return (
    <>
      <Sidebar style={{ height: '100vh' }}>
        {modalOpen ? (
          <CreateUserModal
            modalOpen={modalOpen}
            closeModal={closeModal}
            selectedOrder={selectedOrder}
          />
        ) : null}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box className={classes.mapContainer}>
            <Box className={classes.titleBox}>
              <Typography variant="h6">{t('workOrders')}</Typography>
            </Box>
            {data.length
              ? data.map(order => (
                  <Accordion
                    key={order.id}
                    className={classes[getColorClass(order.status)]}
                    expanded={expandedAccordion === order.id}
                    onChange={handleExpand(order)}
                  >
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography>
                        {order.number}# - {order.name} -{' '}
                        {dayjs(order.date).format('DD/MM/YYYY')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Typography align="right">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={order.status !== 'CREATED'}
                              onChange={() => statusHandler(order)}
                              disabled={order.status !== 'CREATED'}
                            />
                          }
                          label="Spremno"
                        />
                        <IconButton
                          onClick={() => openModal(order)}
                          disabled={order.status !== 'CREATED'}
                          color="primary"
                          size="small"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => setOrderForDeletion(order)}
                          color="secondary"
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="h6"
                          style={{ marginRight: '10px' }}
                        >
                          {t('navigator')}:
                        </Typography>
                        <Typography>
                          {navigators.find(
                            navigator => navigator.id === order.navigatorId
                          )?.name || '#N/A'}
                        </Typography>
                      </Box>
                      <Divider variant="fullWidth" />
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="h6"
                          style={{ marginRight: '10px' }}
                        >
                          {t('machine')}:
                        </Typography>
                        <Typography>
                          {machines.find(
                            machine => machine.id === order.machineId
                          )?.name || '#N/A'}
                        </Typography>
                      </Box>
                      <Divider variant="fullWidth" />
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="h6"
                          style={{ marginRight: '10px' }}
                        >
                          {t('field')}:
                        </Typography>
                        <Typography>
                          {fields.find(field => field.id === order.fieldId)
                            ?.name || '#N/A'}
                        </Typography>
                      </Box>
                      <Divider variant="fullWidth" />
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="h6"
                          style={{ marginRight: '10px' }}
                        >
                          {t('work')}:
                        </Typography>
                        <Typography>{order.agrotechnicalMethod}</Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))
              : null}
          </Box>
          <Box>
            <Button
              onClick={() => openModal('')}
              fullWidth
              variant="contained"
              color="primary"
            >
              {t('newOrder')}
            </Button>
          </Box>
        </Box>
      </Sidebar>
      <Box width="100%" height="100%">
        {isLoaded ? (
          <GoogleMap
            center={center}
            mapContainerStyle={containerStyle}
            zoom={17}
            options={options}
            onLoad={onLoad}
            ref={mapRef}
          >
            {selectedPlot && (
              <>
                {selectedPlot.geometry.type === 'Feature' && (
                  <Polygon
                    paths={getPolygonCoords(selectedPlot.geometry.coordinates)}
                  />
                )}
                {selectedPlot.geometry.type === 'Polygon' && (
                  <Polygon
                    paths={getPolygonCoords(selectedPlot.geometry.coordinates)}
                  />
                )}
                {selectedPlot.type === 'Point' && (
                  <Marker
                    position={getLatLng(selectedPlot.geometry.coordinates)}
                  />
                )}
                {selectedPlot.type === 'LineString' && (
                  <Polyline
                    path={selectedPlot.geometry.coordinates.map(getLatLng)}
                  />
                )}
                {selectedPlot.type === 'MultiPoint' &&
                  selectedPlot.geometry.coordinates.map((coord, index) => (
                    <Marker key={index} position={getLatLng(coord)} />
                  ))}
              </>
            )}
          </GoogleMap>
        ) : null}
        <Dialog
          open={!!orderForDeletion}
          onClose={closeDeletionModal}
          disableBackdropClick={isLoading}
          disableEscapeKeyDown={isLoading}
        >
          <DialogTitle>{t('confirmDelete')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {orderForDeletion &&
                t('confirmDeleteOrderMessage', {
                  order: orderForDeletion?.name,
                })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeDeletionModal}
              disabled={isLoading}
              variant="outlined"
              color="default"
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={() => deleteOrder(orderForDeletion.id)}
              disabled={isLoading}
              variant="outlined"
              color="primary"
              autoFocus
            >
              {t('clear')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
