import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { createObstacle } from 'endpoints/fieldMaps';

const useStyles = makeStyles(theme => ({
  content: {
    minWidth: '500px',
    padding: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3),
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialog: {
    minWidth: '700px',
  },
}));

const DrawingModal = ({
  open,
  onSave,
  onCancel,
  addObstacleMode,
  refetch,
  plotData,
  obstacle,
  setModalOpen,
  setAddObstacleMode,
  clearObstacleShape,
}) => {
  const classes = useStyles();
  const [shapeName, setShapeName] = useState('');
  const [obsName, setObsName] = useState('');


  const handleSaveClick = () => {
    onSave(shapeName);
  };

  const resetState = () => {
    setShapeName('');
    setObsName('');
  };

  // Adding obstacle name here, this way, because the function which creates the entire object with an obstacle is only triggered on drawingComplete.
  // wrapping it in useMemo, to trigger again on name change will cause big lag because re-creating objects.
  const obstacleNamed = useMemo(() => {
    if (obstacle?.length) {
      obstacle[obstacle.length - 1].properties.name = obsName;
    }
    return obstacle;
  }, [obsName, obstacle]);

  const mapId = plotData?.id;

  const mutation = useMutation(() => createObstacle(mapId, obstacleNamed), {
    onSuccess: () => {
      try {
        toast.success('Obstacle added successfully!');
        resetState();
        refetch();
        clearObstacleShape();
        setModalOpen(false);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  const handleSave = async() => {
    await mutation.mutateAsync(mapId, obstacleNamed);
    resetState();
    setAddObstacleMode(false);
  };

  return (
    <>
      {!addObstacleMode ? (
        <Dialog open={open} onClose={onCancel}>
          <DialogTitle className={classes.title}>Enter shape name</DialogTitle>
          <DialogContent className={classes.content}>
            <TextField
              autoFocus
              margin="dense"
              variant="outlined"
              label="Name"
              fullWidth
              value={shapeName}
              onChange={e => setShapeName(e.target.value)}
            />
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSaveClick}
              color="primary"
              disabled={!shapeName.trim()}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={onCancel}>
          <DialogTitle className={classes.title}>Dodaj prepreku</DialogTitle>
          <DialogContent className={classes.content}>
            <TextField
              autoFocus
              margin="dense"
              variant="outlined"
              label="Obstacle name"
              fullWidth
              value={obsName}
              onChange={e => setObsName(e.target.value)}
            />
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              disabled={!obsName.trim()}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DrawingModal;
