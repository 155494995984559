import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  LinearProgress,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { useMutation } from 'react-query';
import { deleteObstacle, getAllUserObstacles } from 'endpoints/fieldMaps';
import { makeStyles } from '@material-ui/styles';
import { useQuery } from 'react-query';
import { updateMap, updateObstacleName } from 'endpoints/fieldMaps';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '24px',
    minWidth: '500px',
    borderRadius: '8px',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  obstacleContainer: {
    marginTop: '10px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const EditModal = ({ open, onClose, refetch, plotData }) => {
  const classes = useStyles();
  const name = plotData.name;
  const mapId = plotData?.id;

  const [inputName, setInputName] = useState(name || '');
  const [isNameValid, setIsNameValid] = useState(true);
  const [selectedObstacle, setSelectedObstacle] = useState('');
  const [selectedObstacleDetails, setSelectedObstacleDetails] = useState(null);
  const [selectedObstacleName, setSelectedObstacleName] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const handleInputChange = event => {
    const name = event.target.value;
    setInputName(name);
  };

  useEffect(() => {
    setInputName(name || '');
}, [name]);

  const handleObstacleInputNameChange = (event) => {
    setSelectedObstacleName(event.target.value);
  };

  const handleOptionChange = (event, value) => {
    setSelectedOption(value);

    if (value) {
      setSelectedObstacle(value.id);
      setSelectedObstacleDetails(value);
      setSelectedObstacleName(value.properties.name);
    } else {
      setSelectedObstacle('');
      setSelectedObstacleDetails(null);
      setSelectedObstacleName('');
    }
  };

  const geoData = plotData.geoData;

  const mutation = useMutation(
    () => updateMap({ mapId: mapId, name: inputName, geoData: geoData }),
    {
      onSuccess: () => {
        try {
          toast.success('Plot updated successfully!');
          onClose();
          refetch();
        } catch (error) {
          toast.error('Error');
        }
      },
    },
  );

  const { data: obstacles, isLoading, refetch: refetchObstacles } = useQuery(
    ['obstacles', mapId],
    () => getAllUserObstacles(mapId),
    {
      enabled: open,
      onError: error => {
        toast.error(error);
      },
    },
  );

  useEffect(() => {
    if (open) {
      refetchObstacles();
    }
  }, [open, refetchObstacles]);


  const handleSave = async() => {
    if (inputName) {
      await mutation.mutateAsync({ mapId: mapId, name: inputName });
      resetState();
      setIsNameValid(true);
      onClose();
    } else {
      setIsNameValid(false);
    }
  };

  const obstacleNameMutation = useMutation(updateObstacleName);
  const obstacleDeleteMutation = useMutation(deleteObstacle);


  const handleRemoveObstacle = async() => {
    const id = plotData.id;
    const { properties } = selectedObstacleDetails;
    const obstacleId = properties.obstacleId;

      try {
        await obstacleDeleteMutation.mutateAsync({ id, obstacleId });
        toast.success('Successfully deleted an obstacle');
        refetch();
        resetState();
        onClose();
      } catch (error) {
        toast.error('Error deleting obstacle');
      }
  };

  const handleRenameObstacle = async() => {
    const id = plotData.id;
    const { properties } = selectedObstacleDetails;
    const obstacleId = properties.obstacleId;
    const name = selectedObstacleName;

      try {
        await obstacleNameMutation.mutateAsync({ id, obstacleId, name });
        toast.success('Successfully renamed obstacle');
        refetch();
        onClose();
      } catch (error) {
        toast.error('Error renaming obstacle');
      }
  };

  const getOptionLabel = option => {
    if (option && option.properties && option.properties.name) {
      return option.properties.name;
    }
    return '';
  };

  const options = obstacles?.map(obstacle => ({
    ...obstacle,
    name:
      obstacle.properties && obstacle.properties.name
        ? obstacle.properties.name
        : '',
  }));

  const resetState = () => {
    setInputName(name || '');
    setIsNameValid(true);
    setSelectedObstacle('');
    setSelectedObstacleDetails(null);
    setSelectedObstacleName('');
    setSelectedOption(null);
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Box>
        {isLoading && <LinearProgress />}
        <Box className={classes.paper}>
          <Typography variant="h5" gutterBottom>
            Edit {name}
          </Typography>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={inputName}
            onChange={handleInputChange}
            error={!isNameValid}
            helperText={!isNameValid && 'Name is required'}
            sx={{ mt: 2 }}
            style={{ marginBottom: '10px' }}
          />
          {obstacles && (
            <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
              <InputLabel id="obstacle-select-label"></InputLabel>
              <Autocomplete
                options={options}
                getOptionLabel={getOptionLabel}
                value={selectedOption}
                onChange={handleOptionChange}
                renderInput={params => (
                  <TextField {...params} label="Obstacles" variant="outlined" />
                )}
              />
            </FormControl>
          )}

          {selectedObstacleName && (
            <Box className={classes.obstacleContainer}>
              <TextField
                label="Obstacle Name"
                variant="outlined"
                fullWidth
                onChange={handleObstacleInputNameChange}
                value={selectedObstacleName}
                className={classes.obstacleName}
                style={{ marginBottom: '10px' }}
              />
              <Box className={classes.buttonBox}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleRemoveObstacle}
                >
                  Remove
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRenameObstacle}
                >
                  Rename
                </Button>
              </Box>
            </Box>
          )}
          <Box className={classes.buttonContainer}>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

EditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};

export default EditModal;
