export const roles = {
  superAdmin: 'SUPER_ADMIN',
  distributor: 'DISTRIBUTOR',
  user: 'USER',
};

export const machineTypes = [
  { type: 'SEED_DRILL', name: 'seedDrill' },
  { type: 'SPREADER', name: 'spreader' },
  { type: 'SPRAYER', name: 'sprayer' },
  { type: 'ATTACHMENT', name: 'attachment' },
];

export const hangingTypes = [
  { type: 'DRAGGED', name: 'dragged' },
  { type: 'CARRIED', name: 'carried' },
];

export const workTypes = {
  SEED_DRILL: 'Djubrenje',
  SPRAYER: 'Prskanje',
  SPREADER: 'Setva',
  AttachedMachines: [
    'Oranje',
    'Podrivanje',
    'Priprema',
    'Kultiviranje',
    'Striptil',
    'Valjanje',
    'Mapiranje',
    'Drenaža',
    'Ostalo',
  ],
};
