import React, { useState } from 'react';
import { IconButton, Box, Avatar, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconSettings from '@material-ui/icons/Settings';
import CreateUserModal from './CreateUserModal';
import { useQueries } from 'react-query';
import { getUsers } from 'endpoints/users';
import { getAllDistibutors } from 'endpoints/distributors';
import { getUserNavigators } from 'endpoints/navigators';
import CircularLoader from 'components/Loader';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import DeleteUserDialog from './DeleteUserDialog';
import DeleteNavigatorDialog from './DeleteNavigatorDialog';
import LockIcon from '@material-ui/icons/Lock';
import PasswordModal from './PasswordModal';
import { isDistributor } from 'utils/distributor';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import NavigatorModal from './NavigatorModal/';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import dayjs from 'dayjs';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  makeStyles,
} from '@material-ui/core';

import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: 'rgba(229, 57, 53, .3)',
  },
}));

export default function UserHandling() {
  const [selectedUser, setSelectedUser] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userForDeletion, setUserForDeletion] = useState(null);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [userForPassword, setUserForPassword] = useState(null);
  const [selectedNavigator, setSelectedNavigator] = useState('');
  const [navigatorModalOpen, setNavigatorModalOpen] = useState(false);
  const [navigatorUser, setNavigatorUser] = useState('');
  const [navigatorForDeletion, setNavigatorForDeletion] = useState(null);
  const [deleteNavigatorOpen, setDeleteNavigatorOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [distributorFilter, setDistributorFilter] = useState('');
  const [unassignedUserFilter, setUnassignedUserFilter] = useState(false);
  const [resetCounter, setResetCounter] = useState(new Date());

  const queryClient = useQueryClient();

  const { t } = useTranslation('form');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : null;

  const openModal = user => {
    setModalOpen(true);
    setSelectedUser(user);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedUser('');
    setResetCounter(new Date());
  };

  const openDeletionModal = user => {
    setUserForDeletion(user);
    setDeleteModalOpen(true);
  };

  const closeDeletionModal = () => {
    setUserForDeletion(null);
    setDeleteModalOpen(false);
  };

  const openPasswordModal = user => {
    setUserForPassword(user);
    setPasswordModalOpen(true);
  };

  const closePasswordModal = () => {
    setUserForPassword(null);
    setPasswordModalOpen(false);
  };

  const openNavigatorModal = (navigator, userId) => {
    if (navigator) {
      navigator.fromDate = dayjs(navigator.fromDate);
      navigator.toDate = dayjs(navigator.toDate);
      navigator.fromDateViewerLicence = dayjs(navigator.fromDateViewerLicence);
      navigator.toDateViewerLicence = dayjs(navigator.toDateViewerLicence);
    }
    setNavigatorModalOpen(true);
    setSelectedNavigator(navigator);
    setNavigatorUser(userId);
  };

  const closeNavigatorModal = () => {
    setNavigatorModalOpen(false);
    setSelectedNavigator('');
    setNavigatorUser('');
  };

  const openNavigatorDeletion = navigator => {
    setNavigatorForDeletion(navigator);
    setDeleteNavigatorOpen(true);
  };

  const closeNavigatorDeletion = () => {
    setNavigatorForDeletion(null);
    setDeleteNavigatorOpen(false);
  };

  const queries = useQueries([
    {
      queryKey: 'users',
      queryFn: getUsers,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'distributors',
      queryFn: getAllDistibutors,
      refetchOnWindowFocus: false,
      enabled: !isDistributor(),
    },
  ]);

  let [users, distributors] = queries;

  const navigatorQueries = useQueries(
    users.data?.map(user => ({
      queryKey: ['navigators', user.id],
      queryFn: () => getUserNavigators(user.id),
      refetchOnWindowFocus: false,
      enabled: Boolean(user.id),
    })) || []
  );

  const isLoading = queries.some(query => query.isLoading && query.enabled);
  const isError = queries.some(query => query.isError && query.enabled);
  const isDataLoaded = queries.every(query => query.isSuccess || query.isIdle);

  if (isLoading) {
    return <CircularLoader isLoading={true} />;
  }

  if (isError) {
    return <Redirect to="/errors/error-404" />;
  }

  if (!isDataLoaded) {
    return null;
  }

  users.data.forEach((user, index) => {
    user.navigators = navigatorQueries[index].data?.map(navigator => {
      return { ...navigator, userId: user.id };
    });
  });


  // filtering
  users.data = users.data.filter(user => {
    if (distributorFilter && user.distributors[0] !== distributorFilter) {
      return false;
    }
    if (unassignedUserFilter && user.distributors.length !== 0) {
      return false;
    }
    if (!unassignedUserFilter && !distributorFilter && user.distributors.length === 0) {
      return false;
    }
    return true;
  });

  return (
    <Box width="100%" display="flex" alignContent="center">
      {modalOpen ? (
        <CreateUserModal
          distributors={distributors?.data}
          modalOpen={modalOpen}
          closeModal={closeModal}
          selectedUser={selectedUser}
        />
      ) : null}
      {navigatorModalOpen ? (
        <NavigatorModal
          distributors={distributors?.data}
          modalOpen={navigatorModalOpen}
          closeModal={closeNavigatorModal}
          selectedNavigator={selectedNavigator}
          navigatorUser={navigatorUser}
        />
      ) : null}
      <DeleteUserDialog
        modalOpen={deleteModalOpen}
        selectedUser={userForDeletion}
        handleClose={closeDeletionModal}
      />
      <DeleteNavigatorDialog
        modalOpen={deleteNavigatorOpen}
        selectedNavigator={navigatorForDeletion}
        handleClose={closeNavigatorDeletion}
      />
      <PasswordModal
        modalOpen={passwordModalOpen}
        selectedUser={userForPassword}
        closeModal={closePasswordModal}
      />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                {!isDistributor() && (
                  <IconButton
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                  >
                    <FilterListIcon />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                {t('organization.organizationGeneralSettings.picture')}
              </TableCell>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('email')}</TableCell>
              <TableCell>
                {t('organization.organizationGeneralSettings.city')}
              </TableCell>
              <TableCell>
                {t('organization.organizationGeneralSettings.country')}
              </TableCell>
              <TableCell>
                {t('organization.organizationGeneralSettings.phone')}
              </TableCell>
              {!isDistributor() ? (
                <TableCell>{t('distributor')} </TableCell>
              ) : null}
              <TableCell align="right">
                <IconButton onClick={() => openModal()} color="primary">
                  <PersonAddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.data.map(row => (
              <Row
                key={row.id}
                row={row}
                openModal={openModal}
                openPasswordModal={openPasswordModal}
                openDeletionModal={openDeletionModal}
                openNavigatorModal={openNavigatorModal}
                openNavigatorDeletion={openNavigatorDeletion}
                distributors={distributors.data}
                resetCounter={resetCounter}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {!isDistributor() && (
          <Box width={300} p={2}>
            <Box display="flex">
              <TextField
                label={t('distributor')}
                variant="outlined"
                fullWidth
                name="distributors"
                select
                value={distributorFilter}
                onChange={e => setDistributorFilter(e.target.value)}
              >
                {[...distributors.data, JSON.parse(localStorage.getItem('userData'))].map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
              <Box display="flex" alignItems="center" ml={2}>
                <IconButton
                  size="small"
                  onClick={() => setDistributorFilter('')}
                >
                  <ClearIcon color="error" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}
        <Box display="flex" p={2}>
          <FormControlLabel control={<Switch checked={unassignedUserFilter} onChange={e => setUnassignedUserFilter(prevVal => !prevVal)} />} label={t('unassignedUsers')} />
        </Box>
      </Popover>
    </Box>
  );
}

function Row(props) {
  const {
    row,
    openModal,
    openPasswordModal,
    openDeletionModal,
    openNavigatorModal,
    openNavigatorDeletion,
    distributors,
    resetCounter,
  } = props;
  const [open, setOpen] = useState(false);

  const { t } = useTranslation('form');

  const queryClient = useQueryClient();
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            <SettingsRemoteIcon color={open ? 'secondary' : 'inherit'} />
          </IconButton>
        </TableCell>
        <TableCell>
          <Avatar src={`${row.picture}?timestamp=${resetCounter}`} />
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.city}</TableCell>
        <TableCell>{row.country}</TableCell>
        <TableCell>{row.phone}</TableCell>
        {!isDistributor() ? (
          <TableCell>
            {
              [...distributors, JSON.parse(localStorage.getItem('userData'))].find(
                distributor => distributor.id === row?.distributors[0]
              )?.name || '-'
            }
          </TableCell>
        ) : null}
        <TableCell align="right">
          <>
            <IconButton onClick={() => openModal(row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => openPasswordModal(row)}>
              <LockIcon />
            </IconButton>
            <IconButton
              onClick={() => openDeletionModal(row)}
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          </>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0 }}
            colSpan={12}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('serialNumber')}</TableCell>
                  <TableCell>{t('validFrom')}</TableCell>
                  <TableCell>{t('validTo')}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => openNavigatorModal(null, row.id)}
                      color="primary"
                    >
                      <AddToQueueIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              {!row.navigators.length ? (
                <TableBody style={{ margin: '1em' }}>
                  <TableRow>
                    <TableCell>
                      <Typography color="error">{t('noNavigators')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {row.navigators.map(navigator => (
                    <TableRow key={navigator.serialNumber} className={dayjs().isAfter(dayjs(navigator.toDate)) ? classes.row : null}>
                      <TableCell>{navigator.serialNumber}</TableCell>
                      <TableCell>
                        {dayjs(navigator.fromDate).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell>
                        {dayjs(navigator.toDate).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => openNavigatorModal(navigator, null)}
                        >
                          <IconSettings style={{ cursor: 'pointer' }} />
                        </IconButton>
                        <IconButton
                          onClick={() => openNavigatorDeletion(navigator)}
                          color="secondary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}