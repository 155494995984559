const convertShapeToGeoJSON = shape => {
  let coordinates = [];
  let type = '';

  if (shape instanceof window.google.maps.Marker) {
    const position = shape.getPosition();
    coordinates = [[position.lng(), position.lat()]];
    type = 'Point';
  } else if (shape instanceof window.google.maps.Polyline) {
    coordinates = shape
      .getPath()
      .getArray()
      .map(latLng => [latLng.lng(), latLng.lat()]);
    type = 'LineString';
  } else if (shape instanceof window.google.maps.Polygon) {
    const path = shape.getPath().getArray();
    coordinates = path.map(latLng => [latLng.lng(), latLng.lat()]);
    coordinates.push(coordinates[0]);
    type = 'Polygon';
  } else if (shape instanceof window.google.maps.Rectangle) {
    const bounds = shape.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    coordinates = [
      [sw.lng(), sw.lat()],
      [ne.lng(), sw.lat()],
      [ne.lng(), ne.lat()],
      [sw.lng(), ne.lat()],
      [sw.lng(), sw.lat()],
    ];

    type = 'Polygon';
  } else {
    console.error('Unsupported shape type');
    return null;
  }

  const geoJSON = {
    type: 'FeatureCollection',
    properties: {
      class: 'parcel',
    },
    features: [
      {
        type: 'Feature',
        geometry: {
          type: type,
          coordinates: [coordinates],
        },
        properties: {
          FillColor: 'Yellow',
          FillOpacity: 0.3,
          BorderColor: '#FF0000',
          BorderWidth: 1,
          class: 'outline',
        },
      },
    ],
  };

  return geoJSON;
};

export default convertShapeToGeoJSON;