import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.REACT_APP_SENTRY_ENV !== ('development' || 'local'),
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
  }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
