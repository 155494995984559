import React, {Fragment, Suspense, useState} from 'react';
import {renderRoutes} from 'react-router-config';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {LinearProgress} from '@material-ui/core';
import {Topbar} from './components';
import {PageLoaderContext} from 'context/Page';
import CircularLoader from 'components/Loader';

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
}));

const Auth = props => {
  const {route} = props;

  const classes = useStyles();
  const [isLoadingPage, setPageLoading] = useState(false);

  return (
    <Fragment>
      <Topbar/>
      <PageLoaderContext.Provider value={{isLoadingPage, setPageLoading}}>
        <main className={classes.content}>
          {isLoadingPage ? <CircularLoader isLoading={true}/> :
            <Suspense fallback={<LinearProgress/>}>
              {renderRoutes(route.routes)}
            </Suspense>}
        </main>
      </PageLoaderContext.Provider>
    </Fragment>
  );
};

Auth.propTypes = {
  route: PropTypes.object,
};

export default Auth;
