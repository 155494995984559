import { roles } from 'const/roles';

export default [
  {
    pages: [
      {
        title: 'navigators',
        href: '/navigators',
        icon: 'Tractor',
        roles: [roles.user],
      },
      {
        title: 'attachedMachines',
        href: '/attached-machines',
        icon: 'Machines',
        roles: [roles.user],
      },
      {
        title: 'plots',
        href: '/plots',
        icon: 'Plots',
        roles: [roles.user],
      },
      {
        title: 'monitoring',
        href: '/monitoring',
        icon: 'Station',
        roles: [roles.user, roles.distributor, roles.superAdmin],
      },
      {
        title: 'jobs',
        href: '/jobs',
        icon: 'Jobs',
        roles: [roles.user],
      },
      {
        title: 'workOrders',
        href: '/work-orders',
        icon: 'WorkOrders',
        roles: [roles.user],
      },
      {
        title: 'mainMenu',
        href: '/main-menu',
        icon: 'MainMenu',
        roles: [roles.distributor, roles.superAdmin],
      },
      {
        title: 'userHandling',
        href: '/user-handling',
        icon: 'UserHandling',
        roles: [roles.distributor, roles.superAdmin],
      },
      {
        title: 'distributorHandling',
        href: '/distributor-handling',
        icon: 'DistributorHandling1',
        roles: [roles.superAdmin],
      },
      {
        title: 'baseStationHandling',
        href: '/base-station-handling',
        icon: 'Stations',
        roles: [roles.distributor, roles.superAdmin],
      },
      {
        title: 'unassignedNavigators',
        href: '/factory-navigators',
        icon: 'Tractor',
        roles: [roles.superAdmin],
      },
      {
        title: 'appVersion',
        href: '/app-version',
        icon: 'Jobs',
        roles: [roles.superAdmin],
      },
    ],
  },
];
