/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/Auth';
import ErrorLayout from 'layouts/Error';
import DashboardLayout from 'layouts/Dashboard';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import UserHandling from 'views/UserHandling/';
import DistributorHandling from 'views/DistributorHandling/';
import MainMenu from 'views/MainMenu';
import Navigators from 'views/Navigators';
import AttachedMachines from 'views/AttachedMachines';
import { MonitoringContainer } from 'App';
import Plots from 'views/Plots';
import BaseStations from 'views/BaseStations';
import BaseStationHandling from 'views/BaseStationHandling';
import FactoryNavigators from 'views/FactoryNavigators';
import AppVersion from 'views/AppVersion';

import { roles } from 'const/roles';
import WorkOrders from 'views/WorkOrders/WorkOrders';
import Jobs from 'views/Jobs/Jobs';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />,
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401')),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404')),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/navigators',
        exact: true,
        component: ProtectedRoute(Navigators, [roles.user]),
      },
      {
        path: '/attached-machines',
        exact: true,
        component: ProtectedRoute(AttachedMachines, [roles.user]),
      },
      {
        path: '/monitoring',
        exact: true,
        component: ProtectedRoute(MonitoringContainer, [
          roles.user,
          roles.superAdmin,
          roles.distributor,
        ]),
      },
      {
        path: '/plots',
        exact: true,
        component: ProtectedRoute(Plots, [roles.user]),
      },
      {
        path: '/base-stations',
        exact: true,
        component: ProtectedRoute(BaseStations, [roles.user]),
      },
      {
        path: '/jobs',
        exact: true,
        component: ProtectedRoute(Jobs, [roles.user]),
      },
      {
        path: '/work-orders',
        exact: true,
        component: ProtectedRoute(WorkOrders, [roles.user]),
      },
      {
        path: '/main-menu',
        exact: true,
        component: ProtectedRoute(MainMenu, [
          roles.superAdmin,
          roles.distributor,
        ]),
      },
      {
        path: '/user-handling',
        exact: true,
        component: ProtectedRoute(UserHandling, [
          roles.superAdmin,
          roles.distributor,
        ]),
      },
      {
        path: '/distributor-handling',
        exact: true,
        component: ProtectedRoute(DistributorHandling, [roles.superAdmin]),
      },
      {
        path: '/base-station-handling',
        exact: true,
        component: ProtectedRoute(BaseStationHandling, [
          roles.superAdmin,
          roles.distributor,
        ]),
      },
      {
        path: '/app-version',
        exact: true,
        component: ProtectedRoute(AppVersion, [roles.superAdmin]),
      },
      {
        path: '/factory-navigators',
        exact: true,
        component: ProtectedRoute(FactoryNavigators, [roles.superAdmin]),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];

export default routes;
