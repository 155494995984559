import React, { useState, useEffect } from 'react';
import {
  changeAssistanceRequestStatus,
  deleteAssistanceRequest,
} from 'endpoints/monitoring';
import { useQuery } from 'react-query';
import { getAllDistibutors, getDistibutorsById } from 'endpoints/distributors';
import { getUsers } from 'endpoints/users';
import {
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { roles } from 'const/roles';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  autocomplete: {
    marginBottom: '10px',
  },
  tableContainer: {
    flex: 1,
  },
}));

export default function Monitoring({
  data,
  isLoading,
  error,
  refetch,
  setUserIdSetter,
}) {
  const classes = useStyles();
  const [statusMap, setStatusMap] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [countdowns, setCountdowns] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('');

  const userData = JSON.parse(localStorage.getItem('userData'));
  const userRole = userData.roles[0];
  const userId = userData.id;
  const isSuperAdmin = userRole === 'SUPER_ADMIN';
  const isUser = userRole === 'USER';

  const {
    data: distributors,
    error: distributorsError,
    isLoading: loadingDistributors,
  } = useQuery(['getAllDistibutors'], () => getAllDistibutors(userId), {
    enabled: isSuperAdmin,
  });

  const { data: users, error: userError, isLoading: loadingUsers } = useQuery(
    ['getDistibutorsById', selectedDistributor],
    () => getDistibutorsById(selectedDistributor?.id),
    { enabled: isSuperAdmin }
  );

  const {
    data: distributorUsers,
    error: distributorUserError,
    isLoading: loadingDistributorUsers,
  } = useQuery(['getUsers'], () => getUsers(), { enabled: !isSuperAdmin });

  useEffect(() => {
    if (data) {
      const initialStatusMap = {};
      const initialRowData = {};
      data.forEach(row => {
        initialStatusMap[row.id] = row.status;
        initialRowData[row.id] = {
          createdAt: new Date(row.createdAt),
          timeLeft: row.timeLeft,
        };
      });
      setStatusMap(initialStatusMap);
      setRowData(initialRowData);
    }
  }, [data]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedCountdowns = {};
      Object.keys(rowData).forEach(rowId => {
        const { createdAt, timeLeft } = rowData[rowId];
        const currentTime = new Date();
        const expirationTime = new Date(createdAt.getTime() + timeLeft * 1000);
        const remainingTimeInSeconds = Math.max(
          0,
          Math.floor((expirationTime - currentTime) / 1000)
        );
        updatedCountdowns[rowId] = remainingTimeInSeconds;
        if (
          remainingTimeInSeconds === 0 &&
          statusMap[rowId] !== 'MISSED' &&
          statusMap[rowId] !== 'REJECTED' &&
          statusMap[rowId] !== 'ACCEPTED'
        ) {
          handleStatusChange(rowId, 'MISSED');
        }
      });
      setCountdowns(updatedCountdowns);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [rowData]);

  const handleStatusChange = async (assistanceId, newStatus) => {
    try {
      await changeAssistanceRequestStatus(assistanceId, newStatus);
      setStatusMap(prevStatusMap => ({
        ...prevStatusMap,
        [assistanceId]: newStatus,
      }));
    } catch (error) {
      toast.error('Error changing assistance request status:', error);
    }
  };

  const openDeleteModal = rowId => {
    setDeleteModalOpen(rowId);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(null);
  };

  const handleDelete = async assistanceId => {
    try {
      await deleteAssistanceRequest(assistanceId);
      toast.success('Successfully deleted an assistance request');
      refetch();
      closeDeleteModal();
    } catch (error) {
      toast.error('Error deleting an assistance request');
    }
  };

  const handleCopyPassword = password => {
    navigator.clipboard
      .writeText(password)
      .then(() => {
        toast.success('Password copied to clipboard!');
      })
      .catch(error => {
        toast.error('Error copying password to clipboard:', error);
      });
  };

  const handleCopyUserId = userId => {
    navigator.clipboard
      .writeText(userId)
      .then(() => {
        toast.success('User ID  copied to clipboard!');
      })
      .catch(error => {
        toast.error('Error copying user ID to clipboard:', error);
      });
  };

  function formatTime(seconds, statusMap) {
    const excludedStatuses = ['MISSED', 'CREATED'];
    if (!excludedStatuses.includes(statusMap)) {
      return '0';
    }
    if (seconds <= 0) {
      return 'Expired';
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours
      .toString()
      .padStart(2, '0')} : ${minutes
      .toString()
      .padStart(2, '0')} : ${remainingSeconds.toString().padStart(2, '0')}`;
  }

  const handleUserChange = (event, newValue) => {
    setSelectedUser(newValue?.id || null);
    setUserIdSetter(newValue);
  };

  const handleDistributorChange = (event, newValue) => {
    setSelectedDistributor(newValue || null);
    setSelectedUser(null);
  };

  const handleStatusFilterChange = event => {
    setSelectedStatusFilter(event.target.value);
  };

  if (distributorUserError || userError || distributorsError)
    toast.error('Error');

  return (
    <div className={classes.container}>
      <div className={classes.autocomplete} style={{ marginTop: '10px' }}>
        {isSuperAdmin && (
          <Autocomplete
            options={
              distributors
                ? [
                    ...distributors,
                    JSON.parse(localStorage.getItem('userData')),
                  ]
                : []
            }
            getOptionLabel={option => option.name}
            onChange={handleDistributorChange}
            renderInput={params => (
              <TextField
                {...params}
                label="Select a distributor"
                variant="outlined"
              />
            )}
          />
        )}
      </div>
      <div className={classes.autocomplete}>
        {(!loadingUsers || !loadingDistributorUsers) &&
          userRole !== 'USER' &&
          (users || distributorUsers) && (
            <Autocomplete
              options={
                users?.filter(
                  user => !user.roles.includes(roles.distributor)
                ) || distributorUsers
              }
              getOptionLabel={option => option.name}
              onChange={handleUserChange}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select a user"
                  variant="outlined"
                  value={selectedUser || ''}
                />
              )}
            />
          )}
      </div>
      {isLoading || (loadingDistributors && <LinearProgress />)}
      {(selectedUser || isUser) && (
        <div className={classes.autocomplete} style={{ marginTop: '10px' }}>
          <FormControl variant="outlined" style={{ marginBottom: '10px' }}>
            <InputLabel>
              {selectedStatusFilter === '' ? 'Filter' : 'Status'}
            </InputLabel>
            <Select
              value={selectedStatusFilter}
              onChange={handleStatusFilterChange}
              label="Status"
              style={{ minWidth: '100px' }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="CREATED">Created</MenuItem>
              <MenuItem value="ACCEPTED">Accepted</MenuItem>
              <MenuItem value="REJECTED">Rejected</MenuItem>
              <MenuItem value="MISSED">Missed</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      <div className={classes.tableContainer}>
        <TableContainer component={Paper}>
          {!isLoading && (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Serial Number</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Assistance Status</TableCell>
                  <TableCell>Time Left</TableCell>
                  <TableCell>Password</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map(
                  row =>
                    (selectedStatusFilter === '' ||
                      statusMap[row.id] === selectedStatusFilter) && (
                      <TableRow key={row.id}>
                        <TableCell>{row.navigatorSerialNumber}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{statusMap[row.id] || ''}</TableCell>
                        <TableCell>
                          {countdowns[row.id] !== undefined
                            ? formatTime(countdowns[row.id], statusMap[row.id])
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          <div style={{ display: 'none' }}>{row.password}</div>
                          <Tooltip title="Copy Password">
                            <IconButton
                              onClick={() => handleCopyPassword(row.password)}
                              color="primary"
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <div style={{ display: 'none' }}>{row.tvId}</div>
                          <Tooltip title="Copy User ID">
                            <IconButton
                              onClick={() => handleCopyUserId(row.tvId)}
                              color="primary"
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {statusMap[row.id] === 'CREATED' && (
                            <>
                              <IconButton>
                                <CheckIcon
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    window.open(
                                      `teamviewer10://control?device=${row.tvId}`,
                                      '_blank',
                                      'noopener noreferrer'
                                    );
                                    handleStatusChange(row.id, 'ACCEPTED');
                                  }}
                                  style={{ marginRight: '10px' }}
                                >
                                  Accept
                                </CheckIcon>
                              </IconButton>
                              <IconButton>
                                <CloseIcon
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    handleStatusChange(row.id, 'REJECTED')
                                  }
                                >
                                  Decline
                                </CloseIcon>
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton>
                            <DeleteIcon
                              variant="contained"
                              color="secondary"
                              onClick={() => openDeleteModal(row.id)}
                            >
                              Delete
                            </DeleteIcon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          )}
          <Dialog
            open={deleteModalOpen !== null}
            onClose={closeDeleteModal}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              {deleteModalOpen !== null && (
                <p>{`Are you sure you want to delete ${
                  data.find(row => row.id === deleteModalOpen)?.name
                } ?`}</p>
              )}
            </DialogTitle>
            <DialogActions>
              <Button onClick={closeDeleteModal} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => handleDelete(deleteModalOpen)}
                color="secondary"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </TableContainer>
      </div>
    </div>
  );
}
