import React, { useState } from 'react';
import { IconButton, Box, Avatar } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateDistributorModal from './CreateDistributorModal';
import { useQueries } from 'react-query';
import { getAllDistibutors } from 'endpoints/distributors';
import CircularLoader from 'components/Loader';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import DeleteDistributorModal from './DeleteDistributorModal';
import LockIcon from '@material-ui/icons/Lock';
import PasswordModal from './PasswordModal';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

export default function DistributorHandling() {
  const [selectedUser, setSelectedUser] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userForDeletion, setUserForDeletion] = useState(null);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [userForPassword, setUserForPassword] = useState(null);
  const [resetCounter, setResetCounter] = useState(new Date());
  const { t } = useTranslation('form');

  const openModal = user => {
    setModalOpen(true);
    setSelectedUser(user);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedUser('');
    setResetCounter(new Date())
  };

  const openDeletionModal = user => {
    setUserForDeletion(user);
    setDeleteModalOpen(true);
  };

  const closeDeletionModal = () => {
    setUserForDeletion(null);
    setDeleteModalOpen(false);
  };

  const openPasswordModal = user => {
    setUserForPassword(user);
    setPasswordModalOpen(true);
  };

  const closePasswordModal = () => {
    setUserForPassword(null);
    setPasswordModalOpen(false);
  };

  const queries = useQueries([
    {
      queryKey: 'distributors',
      queryFn: getAllDistibutors,
      refetchOnWindowFocus: false,
    },
  ]);

  const isLoading = queries.some(query => query.isLoading && query.enabled);
  const isError = queries.some(query => query.isError && query.enabled);
  const isDataLoaded = queries.every(query => query.isSuccess || query.isIdle);

  if (isLoading) {
    return <CircularLoader isLoading={true} />;
  }

  if (isError) {
    return <Redirect to="/errors/error-404" />;
  }

  if (!isDataLoaded) {
    return null;
  }

  const [users] = queries;

  return (
    <Box width="100%" display="flex" alignContent="center">
      {modalOpen ? (
        <CreateDistributorModal
          modalOpen={modalOpen}
          closeModal={closeModal}
          selectedUser={selectedUser}
        />
      ) : null}
      <DeleteDistributorModal
        modalOpen={deleteModalOpen}
        selectedUser={userForDeletion}
        handleClose={closeDeletionModal}
      />
      <PasswordModal
        modalOpen={passwordModalOpen}
        selectedUser={userForPassword}
        closeModal={closePasswordModal}
      />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>{t('organization.organizationGeneralSettings.picture')}</TableCell>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('email')}</TableCell>
              <TableCell>{t('organization.organizationGeneralSettings.city')}</TableCell>
              <TableCell>{t('organization.organizationGeneralSettings.country')}</TableCell>
              <TableCell>{t('organization.organizationGeneralSettings.phone')}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => openModal()} color="primary">
                  <PersonAddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.data.map(row => (
              <Row
                key={row.id}
                row={row}
                openModal={openModal}
                openPasswordModal={openPasswordModal}
                openDeletionModal={openDeletionModal}
                resetCounter={resetCounter}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Row(props) {
  const { row, openModal, openPasswordModal, openDeletionModal, resetCounter } = props;

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Avatar src={`${row.picture}?timestamp=${resetCounter}`} />
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.city}</TableCell>
        <TableCell>{row.country}</TableCell>
        <TableCell>{row.phone}</TableCell>
        <TableCell align="right">
          <>
            <IconButton onClick={() => openModal(row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => openPasswordModal(row)}>
              <LockIcon />
            </IconButton>
            <IconButton onClick={() => openDeletionModal(row)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
