import axiosInstance from '.';

export const createDistributor = async data => {
  return axiosInstance.post('/distributors', data).then(res => res.data);
};

export const getAllDistibutors = async data => {
  return axiosInstance.get('/distributors', data).then(res => res.data);
};

export const getDistibutorsById = async(id) => {
  return axiosInstance.get(`/distributors/${id}/users`).then(res => res.data);
};

export const updateDistributor = async distributor => {
  return axiosInstance
    .put(`/distributors/${distributor.id}`, distributor)
    .then(res => res.data);
};

export const deleteDistributor = async distributorId => {
  return axiosInstance
    .delete(`/distributors/${distributorId}`)
    .then(res => res.data);
};

export const changeOwnership = async(userId, distributorId) => {
  return axiosInstance.post('/distributors/ownership', {userId, distributorId}).then(res => res.data);
};
