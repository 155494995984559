import React, {useState} from 'react';
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, IconButton, TableBody, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getUnassignedNavigators } from 'endpoints/navigators';
import dayjs from 'dayjs';
import IconSettings from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import NavigatorModal from './NavigatorModal';
import CircularLoader from 'components/Loader/CircularLoader/CircularLoader';
import DeleteNavigatorDialog from 'views/UserHandling/DeleteNavigatorDialog/DeleteNavigatorDialog';
import DeleteIcon from '@material-ui/icons/Delete';


export default function FactoryNavigators() {
  const [selectedNavigator, setSelectedNavigator] = useState('');
  const [navigatorModalOpen, setNavigatorModalOpen] = useState(false);
  const [navigatorForDeletion, setNavigatorForDeletion] = useState(null);
  const [deleteNavigatorOpen, setDeleteNavigatorOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    row: {
      backgroundColor: 'rgba(229, 57, 53, .3)',
    },
  }));

  const { data, isLoading } = useQuery(
    'getUnassignedNavigators',
    getUnassignedNavigators,
    { refetchOnWindowFocus: false },
  );


  const { t } = useTranslation('form');

  const openNavigatorModal = (navigator) => {
    if (navigator) {
      navigator.fromDate = dayjs(navigator.fromDate);
      navigator.toDate = dayjs(navigator.toDate);
    }
    setNavigatorModalOpen(true);
    setSelectedNavigator(navigator);
  };

  const closeNavigatorModal = () => {
    setNavigatorModalOpen(false);
    setSelectedNavigator('');
  };

  const openNavigatorDeletion = navigator => {
    setNavigatorForDeletion(navigator);
    setDeleteNavigatorOpen(true);
  };

  const closeNavigatorDeletion = () => {
    setNavigatorForDeletion(null);
    setDeleteNavigatorOpen(false);
  };

  const classes = useStyles();

  if (isLoading) {
    return <CircularLoader isLoading={true} />;
  }

  return (
    <Box width="100%" display="flex" alignContent="center">
      <DeleteNavigatorDialog
        modalOpen={deleteNavigatorOpen}
        selectedNavigator={navigatorForDeletion}
        handleClose={closeNavigatorDeletion}
      />
      {navigatorModalOpen ? (
        <NavigatorModal
          modalOpen={navigatorModalOpen}
          closeModal={closeNavigatorModal}
          selectedNavigator={selectedNavigator}
        />
      ) : null}
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            <TableCell>{t('serialNumber')}</TableCell>
                  <TableCell>{t('validFrom')}</TableCell>
                  <TableCell>{t('validTo')}</TableCell>
                  <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(navigator => (
              <TableRow key={navigator.serialNumber} className={dayjs().isAfter(dayjs(navigator.toDate)) ? classes.row : null}>
              <TableCell>{navigator.serialNumber}</TableCell>
              <TableCell>
                {dayjs(navigator.fromDate).format('DD.MM.YYYY')}
              </TableCell>
              <TableCell>
                {dayjs(navigator.toDate).format('DD.MM.YYYY')}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => openNavigatorModal(navigator, null)}
                >
                  <IconSettings style={{ cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                          onClick={() => openNavigatorDeletion(navigator)}
                          color="secondary"
                        >
                          <DeleteIcon />
                        </IconButton>
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
