import axiosInstance from '.';


export const getAssistanceRequests = async(userId) => {
    return axiosInstance.get(`/viewer/assistances/user/${userId}`).then(res => res.data);
  };

  export const changeAssistanceRequestStatus = async(assistanceId, status) => {
    const data = { newStatus: status };
    return axiosInstance.put(`/viewer/assistances/${assistanceId}/status`, data);
  };

  export const deleteAssistanceRequest = async(assistanceId) => {
    return axiosInstance.delete(`/viewer/assistances/${assistanceId}`);
  };

