import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const isValidCoord = (value, min, max) => {
  if (value === '') return true;

  const coord = parseFloat(value);
  return !isNaN(coord) && coord >= min && coord <= max;
};

const CoordsInput = ({
  onCoordsChange,
  setIsCoordsTabValid,
  setCoordTabShapeName,
}) => {
  const [coords, setCoords] = useState([{ lng: '', lat: '' }]);
  const [name, setName] = useState('');

  useEffect(() => {
    const validCoords = coords.filter(
      coord =>
        isValidCoord(coord.lng, -180, 180) && isValidCoord(coord.lat, -90, 90),
    );

    const geoJson = {
      type: 'FeatureCollection',
      properties: {
        class: 'parcel',
        name: name,
      },
      features: [
        {
          type: 'Feature',
          properties: {
            FillColor: 'black',
            FillOpacity: 0.3,
            BorderColor: 'black',
            BorderWidth: 1,
            class: 'outline',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              validCoords.map(coord => [
                parseFloat(coord.lng),
                parseFloat(coord.lat),
              ]),
            ],
          },
        },
      ],
    };

    if (validCoords.length >= 3) {
      if (validCoords.length === 3) {
        if (
          validCoords[0].lat !== validCoords[2].lat ||
          validCoords[0].lng !== validCoords[2].lng
        ) {
          validCoords.push({
            lat: validCoords[0].lat,
            lng: validCoords[0].lng,
          });
        }
      } else if (
        validCoords.length >= 4 &&
        (validCoords[0].lat !== validCoords[validCoords.length - 1].lat ||
          validCoords[0].lng !== validCoords[validCoords.length - 1].lng)
      ) {
        validCoords.push({
          lat: validCoords[0].lat,
          lng: validCoords[0].lng,
        });
      }

      geoJson.features[0].geometry.coordinates = [
        validCoords.map(coord => [
          parseFloat(coord.lat),
          parseFloat(coord.lng),
        ]),
      ];

      setIsCoordsTabValid(true);
    } else {
      setIsCoordsTabValid(false);
    }

    onCoordsChange(geoJson);
  }, [coords, name]);

  useEffect(() => {
    const validCoords = coords.filter(
      coord =>
        isValidCoord(coord.lng, -180, 180) && isValidCoord(coord.lat, -90, 90),
    );

    const isLastRowFilled =
      coords[coords.length - 1].lng !== '' &&
      coords[coords.length - 1].lat !== '' &&
      isValidCoord(coords[coords.length - 1].lng, -180, 180) &&
      isValidCoord(coords[coords.length - 1].lat, -90, 90);

    setIsCoordsTabValid(
      validCoords.length >= 3 && isLastRowFilled && name !== '',
    );
  }, [coords, name]);

  const handleNameChange = value => {
    setName(value);
    setCoordTabShapeName(value);
  };

  const handleInputChange = (index, field, value) => {
    const updatedCoords = [...coords];
    if (field === 'lat') {
      updatedCoords[index] = { lat: value, lng: updatedCoords[index].lng };
    } else if (field === 'lng') {
      updatedCoords[index] = { lat: updatedCoords[index].lat, lng: value };
    }
    setCoords(updatedCoords);
    onCoordsChange(updatedCoords);
  };

  const handleAddRow = () => {
    const isLastRowFilled =
      coords[coords.length - 1].lng !== '' &&
      coords[coords.length - 1].lat !== '';
    if (isLastRowFilled) {
      setCoords(prevCoords => [...prevCoords, { lng: '', lat: '' }]);
    }
  };

  const handleRemoveRow = index => {
    setCoords(prevCoords => prevCoords.filter((_, i) => i !== index));
  };

  const isScrollable = coords.length > 4;

  return (
    <div>
      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        style={{ marginBottom: '20px' }}
        onChange={e => handleNameChange(e.target.value)}
      />
      <div
        style={{
          maxHeight: isScrollable ? '200px' : 'auto',
          overflowY: isScrollable ? 'auto' : 'hidden',
        }}
      >
        {coords.map((coord, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '10px',
            }}
          >
            <TextField
              label="Lat"
              variant="outlined"
              value={coord.lng}
              error={!isValidCoord(coord.lng, -180, 180)}
              helperText={
                !isValidCoord(coord.lng, -180, 180) ? 'Invalid Coordinates' : ''
              }
              onChange={e => handleInputChange(index, 'lng', e.target.value)}
            />
            <TextField
              label="Lng"
              variant="outlined"
              style={{ marginRight: '10px' }}
              value={coord.lat}
              error={!isValidCoord(coord.lat, -90, 90)}
              helperText={
                !isValidCoord(coord.lat, -90, 90) ? 'Invalid Coordinates' : ''
              }
              onChange={e => handleInputChange(index, 'lat', e.target.value)}
            />
            {index !== coords.length - 1 && (
              <IconButton
                color="secondary"
                onClick={() => handleRemoveRow(index)}
              >
                <RemoveIcon />
              </IconButton>
            )}
            {index === coords.length - 1 && (
              <IconButton
                color="primary"
                onClick={handleAddRow}
                disabled={
                  !isValidCoord(coord.lng, -180, 180) ||
                  !isValidCoord(coord.lat, -90, 90)
                }
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

CoordsInput.propTypes = {
  onCoordsChange: PropTypes.func.isRequired,
  setCoordTabShapeName: PropTypes.func.isRequired,
};

export default CoordsInput;
