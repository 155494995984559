import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({ baseURL: apiUrl });

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');

    if (token && !config.headers.Authorization) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (err) => Promise.reject(err),
);

axiosInstance.interceptors.response.use(
  (res) => res,
  async(err) => {
    if (err.response?.status === 401 && err.response.data.message !== 'Bad credentials!') {
      localStorage.removeItem('access_token');
      localStorage.removeItem('userData');
      window.location.replace('/auth/login');
    }
    console.error(err?.response?.data?.message);
    return Promise.reject(err);
  },
);

export default axiosInstance;