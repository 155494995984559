import React, { useRef, useState } from 'react';
// react-query
import { useMutation, useQuery } from 'react-query';
// hooks
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
// UI
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
// components
import { toast } from 'react-toastify';
import CircularLoader from 'components/Loader/CircularLoader';
// services
import {
  createAppVersion,
  createFile,
  getAppVersion,
} from 'endpoints/appVersion';
// date
import dayjs from 'dayjs';
// styles
import { makeStyles, styled } from '@material-ui/styles';

const basicInitialValues = {
  basic_application_name: '',
  basic_application_version: '',
  basic_app_version_file: '',
};

const enterpriseInitialValues = {
  enterprise_application_name: '',
  enterprise_application_version: '',
  enterprise_app_version_file: '',
};

const useStyles = makeStyles(theme => ({
  boxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  contentHolder: { display: 'flex', gap: '50px', marginTop: '30px' },
  appVersionCard: { border: '1px solid', padding: '10px' },
  appDescriptionHolder: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
  },
  box: {
    display: 'grid',
    gap: '10px',
    alignItems: 'center',
  },
  divider: {
    height: '5px',
    backgroundColor: 'black',
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function AppVersion() {
  const classes = useStyles();
  const { t: formTranslation } = useTranslation('form');
  const { t: pageTranslation } = useTranslation('page');

  const basicFormRef = useRef();
  const enterpriseFormRef = useRef();

  const [basicApp, setBasicApp] = useState();
  const [enterpriseApp, setEnterpriseApp] = useState();

  const basicValidationSchema = Yup.object().shape({
    basic_application_name: Yup.string().required(
      formTranslation('isRequired')
    ),
    basic_application_version: Yup.string().required(
      formTranslation('isRequired')
    ),
  });

  const enterpriceValidationSchema = Yup.object().shape({
    enterprise_application_name: Yup.string().required(
      formTranslation('isRequired')
    ),
    enterprise_application_version: Yup.string().required(
      formTranslation('isRequired')
    ),
  });

  const onSuccessCreateAppFile = resetForm => {
    refetchGetAppVersion();
    resetForm();
    toast.success('Successfully created new app version');
  };

  const onErrorCreateAppFile = res =>
    toast.error(formTranslation('errorHasOccurred'));

  const onSuccessCreateAppVersion = (res, resetForm) => {
    const formData = res.type === 'BASIC' ? basicApp : enterpriseApp;
    if (!formData) {
      toast.error(formTranslation('appVersion.noSelectedFile'));
      return;
    }

    formData.set('id', res.id);
    formData.set('type', res.type);

    createAppFile(formData, {
      onSuccess: onSuccessCreateAppFile(resetForm),
      onError: onErrorCreateAppFile,
    });
  };

  const onErrorCreateAppVersion = e =>
    toast.error(formTranslation('errorHasOccurred'));

  const { isLoading: isLoadingBasicApp, mutate: createBasicApp } = useMutation(
    createAppVersion
  );

  const {
    isLoading: isLoadingEnterpriseApp,
    mutate: createEnterpriseApp,
  } = useMutation(createAppVersion);

  const { mutate: createAppFile } = useMutation(createFile);

  const {
    refetch: refetchGetAppVersion,
    isLoading: isLoadingDataAppVersion,
    data: dataAppVersion,
  } = useQuery('getAppVersion', getAppVersion);

  const isAppFileValid = e => {
    if (
      !e.target.files[0].type === 'application/zip' || // eslint-disable-line
      !e.target.files[0].type === 'application/7zip' || // eslint-disable-line
      !e.target.files[0].type === 'application/rar' || // eslint-disable-line
      !/\.(zip|7zip|rar)$/i.test(e.target.files[0].name) // eslint-disable-line
    ) {
      toast.error(formTranslation('invalidZip'));
      return false;
    }
    return true;
  };

  const handleUploadBasicApp = e => {
    const isValidFile = isAppFileValid(e);
    if (!isValidFile) return;

    const formData = new FormData();
    formData.set('appVersion', e.target.files[0]); // eslint-disable-line
    setBasicApp(formData);
  };

  const handleUploadEnterpriseApp = e => {
    const isValidFile = isAppFileValid(e);
    if (!isValidFile) return;

    const formData = new FormData();
    formData.set('appVersion', e.target.files[0]); // eslint-disable-line
    setEnterpriseApp(formData);
  };

  const handleSubmitBasicApp = (app, { resetForm }) => {
    const { basic_application_name, basic_application_version } = app;

    const appInfo = {
      name: basic_application_name,
      version: basic_application_version,
      type: 'BASIC',
    };

    createBasicApp(appInfo, {
      onSuccess: res => onSuccessCreateAppVersion(res, resetForm),
      onError: onErrorCreateAppVersion,
    });
  };

  const handleSubmitEnterpriseApp = (app, { resetForm }) => {
    const { enterprise_application_name, enterprise_application_version } = app;

    const appInfo = {
      name: enterprise_application_name,
      version: enterprise_application_version,
      type: 'ENTERPRISE',
    };

    createEnterpriseApp(appInfo, {
      onSuccess: res => onSuccessCreateAppVersion(res, resetForm),
      onError: onErrorCreateAppVersion,
    });
  };

  if (isLoadingDataAppVersion) return <CircularLoader fullscreen isLoading />;

  const basicAppData = dataAppVersion.find(item => item.type === 'BASIC');
  const enterpriseAppData = dataAppVersion.find(
    item => item.type === 'ENTERPRISE'
  );

  const returnAppInfo = app => (
    <>
      <Box className={classes.appDescriptionHolder}>
        <Typography variant="h5" gutterBottom>
          {pageTranslation('appVersion.appKeys.name')}:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {app.name}
        </Typography>
      </Box>
      <Box className={classes.appDescriptionHolder}>
        <Typography variant="h5" gutterBottom>
          {pageTranslation('appVersion.appKeys.createdAt')}:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {dayjs(app.createdAt).format('DD-MM-YYYY')}
        </Typography>
      </Box>
      <Box className={classes.appDescriptionHolder}>
        <Typography variant="h5" gutterBottom>
          {pageTranslation('appVersion.appKeys.updatedAt')}:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {dayjs(app.updatedAt).format('DD-MM-YYYY')}
        </Typography>
      </Box>
      <Box className={classes.appDescriptionHolder}>
        <Typography variant="h5" gutterBottom>
          {pageTranslation('appVersion.appKeys.version')}:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {app.version}
        </Typography>
      </Box>
      <Box className={classes.appDescriptionHolder}>
        <Typography variant="h5" gutterBottom>
          {pageTranslation('appVersion.appKeys.appCode')}:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {app.appCode}
        </Typography>
      </Box>
    </>
  );

  return (
    <Box className={classes.boxWrapper}>
      <Typography variant="h1" gutterBottom>
        {pageTranslation('appVersion.title')}
      </Typography>

      <Box className={classes.contentHolder}>
        <Box className={classes.appVersionCard}>
          <Formik
            innerRef={basicFormRef}
            initialValues={basicInitialValues}
            validationSchema={basicValidationSchema}
            onSubmit={handleSubmitBasicApp}
          >
            {formik => (
              <Box>
                <Box className={classes.box}>
                  <Typography variant="h2" gutterBottom>
                    {pageTranslation('appVersion.basic')}
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography variant="h4" gutterBottom>
                    {pageTranslation('appVersion.currentVersion')}
                  </Typography>
                  {basicAppData ? (
                    returnAppInfo(basicAppData)
                  ) : (
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={classes.error}
                    >
                      {pageTranslation('appVersion.errorGettingApp')}
                    </Typography>
                  )}

                  <Divider className={classes.divider} />
                  <Typography variant="h4" gutterBottom>
                    {pageTranslation('appVersion.newVersion')}
                  </Typography>
                  <TextField
                    label={formTranslation('appVersion.appName')}
                    variant="outlined"
                    name="basic_application_name"
                    value={formik.values.basic_application_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.basic_application_name &&
                      Boolean(formik.errors.basic_application_name)
                    }
                  />
                  <TextField
                    variant="outlined"
                    label={formTranslation('appVersion.appVersion')}
                    name="basic_application_version"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={formik.values.basic_application_version}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.basic_application_version &&
                      Boolean(formik.errors.basic_application_version)
                    }
                  />
                  <Button component="label" variant="contained">
                    {formTranslation('appVersion.uploadFile')}
                    <VisuallyHiddenInput
                      type="file"
                      name="basic_app_version_file"
                      accept=".zip,.rar,.7zip"
                      onChange={handleUploadBasicApp}
                    />
                  </Button>

                  <Button
                    endIcon={
                      isLoadingBasicApp && (
                        <CircularProgress color="inherit" size={20} />
                      )
                    }
                    component="label"
                    variant="contained"
                    onClick={formik.submitForm}
                  >
                    {formTranslation('appVersion.submit')}
                  </Button>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
        <Box className={classes.appVersionCard}>
          <Formik
            innerRef={enterpriseFormRef}
            initialValues={enterpriseInitialValues}
            validationSchema={enterpriceValidationSchema}
            onSubmit={handleSubmitEnterpriseApp}
          >
            {formik => (
              <Box className={classes.box}>
                <Typography variant="h2" gutterBottom>
                  ENTERPRISE
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="h4" gutterBottom>
                  {pageTranslation('appVersion.currentVersion')}
                </Typography>

                {enterpriseAppData ? (
                  returnAppInfo(enterpriseAppData)
                ) : (
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={classes.error}
                  >
                    Application does not exist
                  </Typography>
                )}
                <Divider className={classes.divider} />
                <Typography variant="h4" gutterBottom>
                  {pageTranslation('appVersion.newVersion')}
                </Typography>
                <TextField
                  label={formTranslation('appVersion.appName')}
                  variant="outlined"
                  name="enterprise_application_name"
                  value={formik.values.enterprise_application_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.enterprise_application_name &&
                    Boolean(formik.errors.enterprise_application_name)
                  }
                />
                <TextField
                  variant="outlined"
                  label={formTranslation('appVersion.appVersion')}
                  name="enterprise_application_version"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={formik.values.enterprise_application_version}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.enterprise_application_version &&
                    Boolean(formik.errors.enterprise_application_version)
                  }
                />
                <Button component="label" variant="contained">
                  {formTranslation('appVersion.uploadFile')}
                  <VisuallyHiddenInput
                    type="file"
                    name="enterprise_app_version_file"
                    accept=".zip,.rar,.7zip"
                    onChange={handleUploadEnterpriseApp}
                  />
                </Button>
                <Button
                  endIcon={
                    isLoadingEnterpriseApp && (
                      <CircularProgress color="inherit" size={20} />
                    )
                  }
                  component="label"
                  variant="contained"
                  onClick={formik.submitForm}
                >
                  {formTranslation('appVersion.submit')}
                </Button>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}
