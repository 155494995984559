import axiosInstance from '.';

export const getUsers = async() => {
  return axiosInstance.get('/users').then(res => res.data);
};

export const updateUser = async user => {
  return axiosInstance.put(`/users/${user.id}`, user).then(res => res.data);
};

export const deleteUser = async userId => {
  return axiosInstance.delete(`/users/${userId}`).then(res => res.data);
};

export const createUser = async user => {
  return axiosInstance.post('/users', user).then(res => res.data);
};

export const changePassword = async(user, credentials) => {
  return axiosInstance.post(`/users/${user.id}/password`, credentials).then(res => res.data);
};

export const uploadPhoto = async(userId, formData) => {
  return axiosInstance.post(`/users/${userId}/upload/photo`, formData).then(res => res.data);
};


