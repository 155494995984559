import React, { useEffect, useState } from 'react';
import PlotsMap from './PlotsMap';
import Sidebar from 'components/Sidebar/Sidebar';
import { useQuery } from 'react-query';
import { getUserMaps } from 'endpoints/fieldMaps';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddShpModal from './addShpModal';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import getAreaWithoutObstacles from 'utils/AreaCalc';

const useStyles = makeStyles(theme => ({
  bottomButton: {
    position: 'sticky',
    bottom: '0',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: 0,
    width: '100%',
    height: '48px',
    margin: '30px 0 0 0',
  },
  titleBox: {
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    color: 'white',
    marginBottom: '20px',
  },
  accordion: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
  },
  accordionSummary: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
  },
  accordionDetails: {
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[100],
  },
  accordionButtonStyle: {
    border: '1px solid gray',
    borderRadius: '5px',
    marginTop: '10px',
  },
  mapContainer: {
    height: 'calc(100vh - 128px)',
    overflowY: 'scroll',
  },
}));

const Plots = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [plotData, setPlotData] = useState('');
  const [addObstacleMode, setAddObstacleMode] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const anyExpandedAccordion = !!expandedAccordion;

  const userId = JSON.parse(localStorage.getItem('userData')).id;

  const queryInfo = useQuery(['getUserMaps'], () => getUserMaps(userId));

  const { data, isLoading, refetch } = queryInfo;

  useEffect(() => {
    if (data) {
      const updatedPlot = data.find(plot => plot.id === expandedAccordion);
      if (updatedPlot) {
        setSelectedPlot(updatedPlot.geoData);
      }
    }
  }, [data, expandedAccordion]);

  const handleAccordionChange = plotId => (event, newExpanded) => {
    setExpandedAccordion(newExpanded ? plotId : false);
  };

  const handlePlotClick = plotData => {
    if (plotData.features) {
      setSelectedPlot(plotData.features);
    } else {
      setSelectedPlot(plotData);
    }
  };

  useEffect(() => {
    if (!anyExpandedAccordion) {
      setSelectedPlot('');
    }
  }, [anyExpandedAccordion]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteModalOpen = plot => {
    setDeleteModalOpen(true);
    setPlotData(plot);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setSelectedPlot('');
  };

  const handleUpdateModalClose = () => {
    setUpdateModalOpen(false);
    refetch();
  };

  const handleEdit = plot => {
    setUpdateModalOpen(true);
    setPlotData(plot);
  };

  const handleAddObstacle = () => {
    setAddObstacleMode(!addObstacleMode);
  };

  return (
    <>
      <Sidebar style={{ height: '100vh' }}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box className={classes.titleBox}>
            <Typography variant="h6">Parcele</Typography>
          </Box>
          {isLoading && <LinearProgress />}
          <Box className={classes.mapContainer}>
            {data?.map(plot => {
              // Proveravamo broj prepreka u svakoj parceli, ako je broj >=2, disablujemo addovanje novih
              const obstacleCount = plot?.geoData?.features?.filter(
                feature => feature.properties.class === 'obstacle',
              ).length;
              const isAddObstacleDisabled = obstacleCount >= 2;

              return (
                <Accordion
                  key={plot.id}
                  className={classes.accordion}
                  expanded={expandedAccordion === plot.id}
                  onChange={handleAccordionChange(plot.id)}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    onClick={() => {
                      handlePlotClick(plot.geoData);
                      setPlotData(plot);
                    }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{plot.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Button className={classes.accordionButtonStyle}>
                      Size: {getAreaWithoutObstacles(plot.geoData)} HA
                    </Button>
                    <Button
                      onClick={() => handleDeleteModalOpen(plot)}
                      className={classes.accordionButtonStyle}
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={() => handleEdit(plot)}
                      className={classes.accordionButtonStyle}
                    >
                      Edit
                    </Button>
                    <Button
                      className={classes.accordionButtonStyle}
                      onClick={handleAddObstacle}
                      disabled={isAddObstacleDisabled}
                    >
                      Add an obstacle
                    </Button>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
          <Button
            variant="contained"
            className={classes.bottomButton}
            endIcon={<CloudUploadIcon />}
            onClick={handleOpen}
          >
            Dodaj .shp fajl
          </Button>
        </Box>
      </Sidebar>
      <PlotsMap
        selectedPlot={selectedPlot}
        refetch={refetch}
        addObstacleMode={addObstacleMode}
        plotData={plotData}
        setAddObstacleMode={setAddObstacleMode}
      />
      <AddShpModal open={open} onClose={handleClose} refetch={refetch} />
      <DeleteModal
        open={deleteModalOpen}
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        plotData={plotData}
        refetch={refetch}
      />
      <EditModal
        open={updateModalOpen}
        isOpen={updateModalOpen}
        onClose={handleUpdateModalClose}
        plotData={plotData}
        refetch={refetch}
      />
    </>
  );
};

export default Plots;
