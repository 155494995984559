import React, { useMemo, useState, useEffect } from 'react';
import Sidebar from 'components/Sidebar';
import { getUserNavigators, getNavigatorAnalytics } from 'endpoints/navigators';
import { formatNumber } from 'utils/formatNumber';
import { getUsers } from 'endpoints/users';
import { getAllDistibutors, getDistibutorsById } from 'endpoints/distributors';
import { useQuery } from 'react-query';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';

import PlotsMap from 'views/Plots/PlotsMap';
import { roles } from 'const/roles';

const useStyles = makeStyles(theme => ({
  titleBox: {
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    color: 'white',
    marginBottom: '20px',
  },
  navigator: {
    backgroundColor: theme.palette.grey[500],
    alignItems: 'center',
    padding: theme.spacing(2),
    color: 'white',
    width: '100%',
  },
  accordion: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
  },
  accordionSummary: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
  },
  accordionDetails: {
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[100],
  },
  mapContainer: {
    height: 'calc(100vh - 128px)',
    overflowY: 'scroll',
  },
  gradientBox: {
    background: '#00CC66',
    width: '100%',
    height: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    marginBottom: '5px',
  },
  gradientBoxOffline: {
    background: 'lightGray',
    width: '100%',
    height: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    marginBottom: '5px',
  },
  title: {
    color: 'white',
    textDecoration: 'underline',
  },
  bodyText: {
    color: 'black',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  autocomplete: {
    marginBottom: '10px',
  },
}));

export default function MainMenu() {
  const classes = useStyles();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userRole = userData.roles[0];
  const userId = userData.id;
  const isSuperAdmin = userRole === 'SUPER_ADMIN';

  const {
    data: distributors,
    error: distributorsError,
    isLoading: loadingDistributors,
  } = useQuery(['getAllDistibutors'], () => getAllDistibutors(userId), {
    enabled: isSuperAdmin,
  });
  // kao fabrika samo
  const { data: users, error: userError, isLoading: loadingUsers } = useQuery(
    ['getDistibutorsById', selectedDistributor],
    () => getDistibutorsById(selectedDistributor?.id),
    { enabled: isSuperAdmin }
  );

  // kao diler
  const {
    data: distributorUsers,
    error: distributorUserError,
    isLoading: loadingDistributorUsers,
  } = useQuery(['getUsers'], () => getUsers(), { enabled: !isSuperAdmin });

  const { data, error, isLoading } = useQuery(
    ['getUserNavigators', selectedUser],
    () => getUserNavigators(selectedUser),
    { enabled: !!selectedUser }
  );

  const {
    data: navigatorData,
    error: singleNavigatorError,
    isLoading: singleNavigatorLoading,
  } = useQuery(
    ['getNavigatorAnalytics', expandedAccordion],
    () => getNavigatorAnalytics(expandedAccordion),
    {
      enabled: !!expandedAccordion,
      refetchInterval: expandedAccordion ? 5000 : false,
    }
  );

  const handleAccordionChange = plotId => () => {
    setExpandedAccordion(prev => (prev === plotId ? null : plotId));
  };

  const handleUserChange = (event, newValue) => {
    setSelectedUser(newValue?.id || null);
  };

  const handleDistributorChange = (event, newValue) => {
    setSelectedDistributor(newValue || null);
    setSelectedUser(null);
    // resetujemo selektovanog usera na promenu distributora
  };

  useEffect(() => {
    if (selectedDistributor) {
      getUsers(selectedDistributor.id);
    }
  }, [selectedDistributor]);

  const geoJson = useMemo(
    () => [
      {
        type: 'Point',
        properties: {
          FillColor: 'black',
          FillOpacity: 0.3,
          BorderColor: 'black',
          BorderWidth: 1,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(navigatorData?.navigatorLongitude),
            parseFloat(navigatorData?.navigatorLatitude),
          ],
        },
      },
    ],
    [navigatorData]
  );

  const navigatorCoords = useMemo(
    () => geoJson,
    [
      navigatorData?.navigatorLongitude,
      geoJson,
      navigatorData?.navigatorLatitude,
    ],
    [navigatorData]
  );

  const navigatorMap = useMemo(() => {
    const map = navigatorData?.fieldGeoData;
    return map;
  }, [navigatorData]);

  const handleNavigatorMapData = fieldGeoData => {
    let geoJson = [];

    // uvek cemo imati coordinate navigatora, uvek ce se kreirati validan geoJson, ako postoji mapa, mergovace se, gde ce navigator da bude jedan feature u geoJson-u.
    geoJson.push(...navigatorCoords);

    if (
      navigatorMap &&
      navigatorMap.features &&
      Array.isArray(navigatorMap.features) &&
      navigatorMap.features.length > 0
    ) {
      navigatorMap.features.forEach(feature => {
        geoJson.push(feature);
      });
    }

    if (Array.isArray(fieldGeoData) && fieldGeoData.length > 0) {
      fieldGeoData.forEach(data => {
        geoJson.push(data);
      });
    }

    return geoJson;
  };

  if (!singleNavigatorLoading) {
    handleNavigatorMapData(navigatorCoords);
  }

  const finalGeoJson = useMemo(
    () => handleNavigatorMapData(navigatorData?.fieldGeoData),
    [navigatorCoords, navigatorData && navigatorData.fieldGeoData]
  );

  const updatedAt = navigatorData?.updatedAt;
  const fieldName = navigatorData?.fieldName;

  const hasTenMinutesPassed = () => {
    if (!updatedAt) {
      return false;
    }

    const tenMinutesInMillis = 10 * 60 * 1000;
    const updatedAtTime = new Date(updatedAt).getTime();
    const currentTime = new Date().getTime();
    return currentTime - updatedAtTime > tenMinutesInMillis;
  };
  const boxColor = hasTenMinutesPassed()
    ? classes.gradientBoxOffline
    : classes.gradientBox;

  return (
    <>
      <Sidebar>
        <Box display="flex" flexDirection="column" height="100%">
          <Box className={classes.titleBox}>
            <Typography variant="h6">Glavni meni</Typography>
          </Box>
          {isSuperAdmin && (
            <Autocomplete
              options={
                distributors
                  ? [
                      ...distributors,
                      JSON.parse(localStorage.getItem('userData')),
                    ]
                  : []
              }
              getOptionLabel={option => option.name}
              onChange={handleDistributorChange}
              className={classes.autocomplete}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select a distributor"
                  variant="outlined"
                />
              )}
            />
          )}
          {(!loadingUsers || !loadingDistributorUsers) &&
            (users || distributorUsers) && (
              <Autocomplete
                options={
                  (isSuperAdmin &&
                    users?.filter(
                      user => !user.roles.includes(roles.distributor)
                    )) ||
                  distributorUsers
                }
                getOptionLabel={option => option.name}
                onChange={handleUserChange}
                className={classes.autocomplete}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select a user"
                    variant="outlined"
                    value={selectedUser || ''}
                  />
                )}
              />
            )}
          {isLoading || (loadingDistributors && <LinearProgress />)}
          {!data?.length && selectedUser && (
            <Box className={classes.titleBox}>
              <Typography variant="h6">Nema dodatih navigatora.</Typography>
            </Box>
          )}
          {(error ||
            singleNavigatorError ||
            userError ||
            distributorUserError ||
            distributorsError) && (
            <Box className={classes.titleBox}>
              <Typography variant="h6">Doslo je do greške.</Typography>
            </Box>
          )}
          {!isLoading && (
            <Box className={classes.mapContainer}>
              {data?.map(plot => (
                <Accordion
                  key={plot.id}
                  className={classes.accordion}
                  expanded={expandedAccordion === plot.id}
                  onChange={handleAccordionChange(plot.id)}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{plot.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {!singleNavigatorLoading && (
                      <div className={classes.container}>
                        {Object.keys(navigatorData || {}).length === 0 ? (
                          <Box className={classes.navigator}>
                            <Typography variant="h6">
                              Nema aktivnog posla.
                            </Typography>
                          </Box>
                        ) : (
                          <>
                            <Box className={boxColor}>
                              <Typography
                                variant="h6"
                                className={classes.title}
                              >
                                Posao
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.bodyText}
                              >
                                {navigatorData?.agrotechnicalMethod || 'n/a'}
                              </Typography>
                            </Box>
                            <Box className={boxColor}>
                              <Typography
                                variant="h6"
                                className={classes.title}
                              >
                                Mašina
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.bodyText}
                              >
                                {navigatorData?.machineName || 'n/a'}
                              </Typography>
                            </Box>
                            <Box className={boxColor}>
                              <Typography
                                variant="h6"
                                className={classes.title}
                              >
                                Parcela
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.bodyText}
                              >
                                {`${navigatorData?.fieldName} (${formatNumber(
                                  navigatorData?.fieldSize
                                )})` || 'n/a'}
                              </Typography>
                            </Box>
                            <Box className={boxColor}>
                              <Typography
                                variant="h6"
                                className={classes.title}
                              >
                                Trenutna brzina
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.bodyText}
                              >
                                {`${formatNumber(
                                  navigatorData?.currentSpeed
                                )} km/h` || 'n/a'}
                              </Typography>
                            </Box>
                            <Box className={boxColor}>
                              <Typography
                                variant="h6"
                                className={classes.title}
                              >
                                Obradjena površina
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.bodyText}
                              >
                                {`${formatNumber(
                                  navigatorData?.processedSurface
                                )} HA`}
                              </Typography>
                            </Box>
                            <Box className={boxColor}>
                              <Typography
                                variant="h6"
                                className={classes.title}
                              >
                                Procenat uradjenosti
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.bodyText}
                              >
                                 {`${formatNumber(
                                  navigatorData?.processedSurfacePercent
                                )} %` || 'n/a'}
                              </Typography>
                            </Box>{' '}
                          </>
                        )}
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
        </Box>
      </Sidebar>
      {Object.keys(navigatorData || {}).length > 0 ? (
        <PlotsMap
          selectedPlot={finalGeoJson}
          isNavigatorMap
          fieldName={fieldName}
        />
      ) : (
        <PlotsMap isNavigatorMap />
      )}
    </>
  );
}
