const newMachine = {
  machineType: 'SEED_DRILL',
  name: '',
  section: '',
  width: '',
  workingWidth: [{ordinal: 1, width: ''}],
  hangingType: 'DRAGGED',
  sideDrift: '',
  distanceH: '',
  distanceL: '',
  startTime: '',
  stopTime: '',
  work: 'Djubrenje',
};

export default newMachine;