import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import appEn from 'locales/en/acre.json';
import formEn from 'locales/en/form.json';
import pageEn from 'locales/en/page.json';
import navEn from 'locales/en/nav.json';
import appSr from 'locales/sr/acre.json';
import formSr from 'locales/sr/form.json';
import pageSr from 'locales/sr/page.json';
import navSr from 'locales/sr/nav.json';
import {isDevelopment} from 'utils/util';

i18n
  .use(initReactI18next)
  .init({
    lng: 'sr',
    fallbackLng: 'sr',
    resources: {
      en: {
        app: appEn,
        form: formEn,
        page: pageEn,
        nav: navEn,
      },
      sr: {
        app: appSr,
        form: formSr,
        page: pageSr,
        nav: navSr,
      },
    },
    // files to load
    ns: ['app', 'form', 'page', 'nav'],
    defaultNS: 'app',
    interpolation: {
      escapeValue: false,
    },
    debug: true && isDevelopment(),
  });

export default i18n;
