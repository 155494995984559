const newUser = {
  name: '',
  email: '',
  address: '',
  phone: '',
  city: '',
  country: '',
  picture: '',
  language: '',
  password: '',
  distributorId: '',
};

export default newUser;
