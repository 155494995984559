import React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';

const ProtectedRoute = (WrappedComponent, roles = []) => {
  const AuthGuard = ({ ...props }) => {
    const isLoggedIn = localStorage.getItem('access_token'); // Check if user is logged in
    const userRoles = JSON.parse(localStorage.getItem('userData'))?.roles; // Fetch user roles
    if (!isLoggedIn) {
      return <Redirect to="/auth/login" />;
    }

    if (!userRoles?.length || !userRoles.some(role => roles.includes(role))) {
      return <Redirect to="/errors/error-401" />;
    }

    return <WrappedComponent {...props} />;
  };

  return AuthGuard;
};

export default ProtectedRoute;